// src/components/Tabs.js
import React, { useState } from 'react';

const CustomTabs = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(tabs[0].label);

  

  return (
    <div className=" rounded-md">
      <div className="flex gap-x-10 border-b border-gray-800">
        {tabs.map((tab) => (
          <button
            key={tab.label}
            className={`py-2 pb-7 border-b-2  ${activeTab === tab.label ? 'border-primaryGreen text-white font-semibold' : 'border-transparent text-gray-500 '}`}
            onClick={() => setActiveTab(tab.label)}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div className="py-4">
        {tabs.find((tab) => tab.label === activeTab)?.content}
      </div>
    </div>
  );
};

export default CustomTabs;
