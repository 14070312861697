import React, { useEffect, useState } from 'react';
import Packages from '../../../Components/Dashboard/Packages';
import Revenue from '../../../Components/Dashboard/Revenue';
import { toast } from 'react-toastify';
import { useAppContext } from '../../../UseContext/ContextProvider';
import { useNavigate } from 'react-router-dom';
import { axiosInstance, URL } from '../../../utilities/ConstantData';
import Loader from '../../../Components/UiElements/Loader';
import CountUp from 'react-countup';

const AdminDashboard = () => {
  const navigate = useNavigate();
  const { handleErrors, PackageData, setPackageData,setSearchTxt } = useAppContext();
  const [loading, setLoading] = useState(true);
  const [allUsers, setAllUsers] = useState([]);
  const [allMatches, setAllMatches] = useState([]);
  const [statistics, setStatistics] = useState([
    { title: 'Total Active Users', number: '', percentage: '', route:'/all-users'  },
    { title: 'Total Revenue', number: '750', percentage: '10', route:'/' },
    { title: 'Total Bookings', number: '52', percentage: '15.5' , route:'/'},
    { title: 'Total Matches', number: '', percentage: '', route:'/all-matches'},
  ]);
  const [apiCallsInProgress, setApiCallsInProgress] = useState(2);

  useEffect(() => {
    fetchData();
    fetchUsers();
    fetchMatches();
    setSearchTxt("")
  }, []);

  useEffect(() => {
    if (allUsers.length && allMatches.length) {
      updateStatistics();
    }
  }, [allUsers, allMatches]);

  useEffect(() => {
    if (apiCallsInProgress === 0) {
      setLoading(false);
    }
  }, [apiCallsInProgress]);

  const updateStatistics = () => {
    setStatistics(statistics.map((item, index) => {
      switch (index) {
        case 0:
          return { ...item, number: allUsers.length, percentage: calculateWeeklyGrowth(allUsers) };
        case 3:
          return { ...item, number: allMatches.length, percentage: calculateWeeklyGrowth(allMatches) };
        default:
          return item;
      }
    }));
  };

  const calculateWeeklyGrowth = (data) => {
    const now = new Date();
    const weekStart = new Date(now.setDate(now.getDate() - 7));
    const previousWeekStart = new Date(now.setDate(now.getDate() - 14));
  
    let currentWeekData = 0;
    let previousWeekData = 0;
  
    data.forEach(item => {
      const createdAt = new Date(item.createdAt);
      if (createdAt >= weekStart) currentWeekData++;
      if (createdAt >= previousWeekStart && createdAt < weekStart) previousWeekData++;
    });
  
    const absoluteChange = Math.abs(currentWeekData - previousWeekData);
    const maxValue = Math.max(currentWeekData, previousWeekData);
    const percentageChange = maxValue === 0 ? 0 : (absoluteChange / maxValue) * 100;
    const isIncrease = currentWeekData > previousWeekData;
    const sign = isIncrease ? "" : "-";
    const cappedPercentageChange = Math.min(100, Math.max(1, percentageChange));
    console.log(currentWeekData, "currentWeekData");
    console.log(previousWeekData, "previousWeekData");
  
    return `${sign}${cappedPercentageChange.toFixed(1)}`;
  };
  
  

  const fetchData = async () => {
    try {
      const res = await axiosInstance().get(`${URL}/package/all`);
      const filteredData = res.data.data.filter(item => item.status !== 'deactive');
      setPackageData(filteredData);
    } catch (error) {
      handleError(error);
    } finally {
      setApiCallsInProgress(prev => prev - 1);
    }
  };

  const fetchUsers = async () => {
    try {
      const res = await axiosInstance().get(`${URL}/all-users`);
      const filteredData = res.data.data.filter(item => item.userType !== 'ap%4k45a5sd');
      setAllUsers(filteredData);
    } catch (error) {
      handleError(error);
    } finally {
      setApiCallsInProgress(prev => prev - 1);
    }
  };

  const fetchMatches = async () => {
    try {
      const res = await axiosInstance().get(`${URL}/event/all`);
      const filteredData = res.data.data.filter(item =>
        item?.category?.status !== 'inactive' && 
        item?.category?.status &&
        item?.team1?.status !== 'inactive' &&
        item?.team1?.status &&
        item?.team2?.status !== 'inactive'
        &&item?.team2?.status
      );
      setAllMatches(filteredData);
    } catch (error) {
      handleError(error);
    } finally {
      setApiCallsInProgress(prev => prev - 1);
    }
  };

  const handleError = (error) => {
    const errors = error?.response?.data?.errors;
    const statusCode = error?.response?.status;
    if (statusCode === 401) {
      toast.error(errors);
      localStorage.removeItem('MH_UserData');
      navigate('/Login');
    } else {
      handleErrors(error);
    }
  };


  return loading ? (
    <Loader />
  ) : (
    <div className="text-white">
      <div className="grid grid-cols-12 lg:gap-x-10 gap-y-8">
        <div className="col-span-12">
          <div className="grid grid-cols-12 lg:gap-x-8 gap-y-8">
            <div className="col-span-12 headerText">Users</div>
            <div className="col-span-12">
              <div className="grid grid-cols-12 lg:gap-x-5 gap-y-5">
                {statistics.map((item, index) => {
                  return (
                    <>
                      <div
                        key={index}
                        onClick={()=>navigate(item.route)}
                        className="xl:col-span-3 cursor-pointer md:col-span-6 col-span-12 rounded-2xl bg-primaryBlack border-primaryBorder border-[1px] p-5"
                      >
                        <div className="grid grid-cols-12">
                          <div className="col-span-10">
                            <div className="flex flex-col gap-3">
                              <span className="text-base font-semibold">
                                {item.title}
                              </span>
                              <span className="text-4xl font-[800] font-Mohol">
                              <CountUp end={item.number} />
                              </span>
                              <div className="flex gap-2">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M16 6L18.29 8.29L13.41 13.17L9.41 9.17L2 16.59L3.41 18L9.41 12L13.41 16L19.71 9.71L22 12V6H16Z"
                                    fill={ item.percentage>0 ? "#00B69B" : "red"}
                                  />
                                </svg>
                                <span className={`${item.percentage>0 ? 'text-[#00B69B]' : 'text-red-600' }  font-mohol`}>
                                <CountUp end={item.percentage.replace('-','')} />
                                  %
                                </span>
                                <span>{`${item.percentage < 0 ? "Down" : "Up"} from last week`}</span>
                              </div>
                            </div>
                          </div>
                          <div className="grid col-span-2 items-end justify-end">
                            <svg
                              width="40"
                              height="40"
                              viewBox="0 0 60 60"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                opacity="0.842657"
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M0 30V51C0 55.9706 4.02944 60 9 60H30H51C55.9706 60 60 55.9706 60 51V30V9C60 4.02944 55.9706 0 51 0H30H9C4.02944 0 0 4.02944 0 9V30Z"
                                fill="#88F67E"
                              />
                              <path
                                opacity="0.587821"
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M20.6667 23.3333C20.6667 26.2789 23.0545 28.6667 26 28.6667C28.9455 28.6667 31.3333 26.2789 31.3333 23.3333C31.3333 20.3878 28.9455 18 26 18C23.0545 18 20.6667 20.3878 20.6667 23.3333ZM34 28.6667C34 30.8758 35.7909 32.6667 38 32.6667C40.2091 32.6667 42 30.8758 42 28.6667C42 26.4575 40.2091 24.6667 38 24.6667C35.7909 24.6667 34 26.4575 34 28.6667Z"
                                fill="white"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M25.9778 31.3335C19.6826 31.3335 14.5177 34.5689 14.0009 40.9324C13.9727 41.2791 14.6356 42.0002 14.97 42.0002H36.9956C37.9972 42.0002 38.0128 41.1941 37.9972 40.9335C37.6065 34.3911 32.3616 31.3335 25.9778 31.3335ZM45.2746 42.0002L40.1333 42.0002C40.1333 38.9989 39.1417 36.2293 37.4683 34.001C42.0103 34.0506 45.7189 36.347 45.998 41.2002C46.0092 41.3956 45.998 42.0002 45.2746 42.0002Z"
                                fill="white"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <Packages data={PackageData} />
        <Revenue />
      </div>
    </div>
  );
};

export default AdminDashboard;
