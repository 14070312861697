import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useAppContext } from '../../../UseContext/ContextProvider';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { URL } from '../../../utilities/ConstantData';
import { LabelInput } from '../../../Components/UiElements/TextInputs';
import { PrimaryButton, SecondaryButton } from '../../../Components/UiElements/Buttons';

const CreateUser = () => {
    const { handleErrors, signUpDetails, signUpDetailsSetter, setSignUpDetails } = useAppContext()
    const navigate = useNavigate()
    const [confirmPassword, setConfirmPassword] = useState("")
    const [loading, setLoading] = useState(false)

    useEffect(()=>{
        setConfirmPassword("")
        setSignUpDetails({
            userName: "",
            email: "",
            phone:'',
            password: ""
        })
    },[])

    const validation = () => {
        if (!signUpDetails.userName) {
            toast.error("Full name is required");
            return false;
        }
        if (!signUpDetails.email) {
            toast.error("Email is required");
            return false;
        }
        if (!signUpDetails.phone) {
            toast.error("Phone Number is required");
            return false;
        }
        if (!signUpDetails.password) {
            toast.error("Password is required");
            return false;
        }
        if (!confirmPassword) {
            toast.error("Confirm password is required");
            return false;
        }
        if (signUpDetails.password != confirmPassword) {
            toast.error("Password must be match");
            return false;
        }
        return true;
    };

    const handleCreateAccount = async () => {
        console.log('reached')
        if (!validation()) {
            return null
        }
        setLoading(true)
        axios.post(`${URL}/signup`, signUpDetails)
            .then((res) => {
                setLoading(false)
                toast.success("User created successfully!")
                setConfirmPassword("")
                setSignUpDetails({
                    userName: "",
                    email: "",
                    phone:'',
                    password: ""
                })
                navigate(-1)
            })
            .catch((error) => {
                setLoading(false)
                handleErrors(error)
            })
    };

    return (
        <div className='grid grid-cols-12 rounded-lg backdrop-blur-3xl gap-y-10'>
            <div className='col-span-12'>
                <SecondaryButton/>
            </div>
            <div className='col-span-12'>
                <div className='grid grid-cols-12 gap-y-8'>
                    <div className='col-span-12 headerText'>Create User</div>
                    <div className='col-span-6'>
                        <div className='grid grid-cols-12 gap-y-5'>
                            <div className='col-span-12'>
                                <LabelInput name="userName" value={signUpDetails.userName} onChange={(e) => signUpDetailsSetter(e)} label='Full Name' />
                            </div>
                            <div className='col-span-12'>
                                <LabelInput name="email" value={signUpDetails.email} onChange={(e) => signUpDetailsSetter(e)} label='Email' />
                            </div>
                            <div className='col-span-12'>
                                <LabelInput  name="phone" value={signUpDetails.phone} onChange={(e) => signUpDetailsSetter(e)} label='Phone Number' />
                            </div>
                            <div className='col-span-12'>
                                <LabelInput label='Password' name="password" onChange={(e) => signUpDetailsSetter(e)} value={signUpDetails.password} type='password' showEyeIcon={true} />
                            </div>
                            <div className='col-span-12'>
                                <LabelInput label='Confirm Password' type='password' showEyeIcon={true} name="confirm-password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                            </div>
                            <div className='col-span-12'>
                                <PrimaryButton onClick={() => handleCreateAccount()} size='large'> {loading ? 'Creating user...' : 'Create User'}</PrimaryButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateUser
