import React, { useEffect, useState, useRef } from 'react';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../Components/UiElements/Buttons';
import { useAppContext } from '../../UseContext/ContextProvider';
import { useNavigate } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa6';
import { LabelInput } from '../../Components/UiElements/TextInputs';
import { MdCancel, MdOutlineDateRange } from 'react-icons/md';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { RxCross2 } from "react-icons/rx";
import { toast } from 'react-toastify';
import { axiosInstance, StorageURL, URL } from '../../utilities/ConstantData';
import { GoLocation } from 'react-icons/go';
import { TbClockHour12 } from 'react-icons/tb';


const PackageDetails = () => {
  const { selectedUserPackage, selectedUserMatch, convertToAmPm, handleErrors, convertToDateFormat } = useAppContext();
  const [showCheckout, setShowCheckout] = useState(false);
  const [loading2, setLoading2] = useState(false)
  const modalRef = useRef(null);
  const [formData, setFormData] = useState({
    contact: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
    },
    passport: {
      fullName: '',
      passportNumber: '',
      dob: new Date(),
      nationality: '',
      dateOfIssue: new Date(),
      dateOfExpiry: new Date(),
    },
  });
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowCheckout(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!selectedUserPackage) {
      navigate(-1);
    }
  }, [selectedUserPackage]);

  const specificKeys = [
    'matchTicket',
    'externalFlight',
    'hotelAccommodation',
    'groundTransportation',
  ];
  const Features = [
    'Match Ticket',
    'External Flight',
    'Hotel Accommodation',
    'Ground Transportation',
  ];

  const handleInputChange = (e, section) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [section]: {
        ...prev[section],
        [name]: value,
      },
    }));
  };

  const handleDateChange = (date, field, section) => {
    setFormData(prev => ({
      ...prev,
      [section]: {
        ...prev[section],
        [field]: date,
      },
    }));
  };

  console.log(formData, "formData")

  function validateFormData() {
    const { contact, passport } = formData;

    if (!contact.firstName) {
      toast.error('First Name is required.');
      return false;
    }
    if (!contact.lastName) {
      toast.error('Last Name is required.');
      return false;
    }
    if (!contact.email) {
      toast.error('Email is required.');
      return false;
    }
    if (!contact.phoneNumber) {
      toast.error('Phone Number is required.');
      return false;
    }
    if (!passport.fullName) {
      toast.error('Passport Full Name is required.');
      return false;
    }
    if (!passport.passportNumber) {
      toast.error('Passport Number is required.');
      return false;
    }
    if (!passport.nationality) {
      toast.error('Nationality is required.');
      return false;
    }
    if (
      new Date(passport.dateOfExpiry) <=
      new Date(passport.dateOfIssue)
    ) {
      toast.error(
        `The passport's expiration date must always be greater than its date of issuance.`
      );
      return false;
    }

    return true;
  }

  const handleCheckout = () => {
    setShowCheckout(true);
  };

  const handleBooking = () => {
    if (!validateFormData()) {
      return;
    }
    setLoading2(true);
    axiosInstance().post(`${URL}/booking/create`, { ...formData, eventId: selectedUserMatch._id, packageId: selectedUserPackage._id})
      .then(() => {
        setLoading2(false);
        toast.success(`Order booked successfully`);
        navigate("/dashboard");
      })
      .catch((error) => {
        setLoading2(false);
        const errors = error?.response?.data?.errors;
        const statusCode = error?.response?.status;
        if (statusCode === 401) {
          toast.error(errors);
          try {
            localStorage.removeItem('MH_UserData')
          } catch (error) {
            console.log(error)
          } finally {
            navigate("/Login")
          }
        } else {
          handleErrors(error);
        }
      });
  }

  return (
    selectedUserPackage && (
      <>
        <div ref={modalRef} className={`absolute top-0 right-0 grid grid-cols-12 gap-y-5 bg-black rounded-lg ${showCheckout ? 'translate-x-0' : 'translate-x-full'} w-1/2 transition-transform duration-300 p-16 ease-linear z-10`}>
          <RxCross2 color='white' className='absolute top-5 left-5 cursor-pointer' onClick={() => setShowCheckout(false)} />
          <div className='col-span-12 relative'>
            <div className='grid grid-cols-12 gap-y-5'>
              <div className='col-span-12 font-semibold text-xl text-white'>
                Contact Information
              </div>
              <div className='col-span-12'>
                <div className='grid grid-cols-12 gap-5'>
                  <div className='col-span-6'>
                    <LabelInput
                      name='firstName'
                      label='First Name'
                      value={formData.contact.firstName}
                      onChange={(e) => handleInputChange(e, 'contact')}
                      className='bg-[#121212]'
                      placeholder='First Name'
                    />
                  </div>
                  <div className='col-span-6'>
                    <LabelInput
                      name='lastName'
                      label='Last Name'
                      value={formData.contact.lastName}
                      onChange={(e) => handleInputChange(e, 'contact')}
                      className='bg-[#121212]'
                      placeholder='Last Name'
                    />
                  </div>
                  <div className='col-span-6'>
                    <LabelInput
                      name='email'
                      label='Email Address'
                      value={formData.contact.email}
                      onChange={(e) => handleInputChange(e, 'contact')}
                      className='bg-[#121212]'
                      placeholder='Email Address'
                    />
                  </div>
                  <div className='col-span-6'>
                    <LabelInput
                      name='phoneNumber'
                      label='Phone Number'
                      value={formData.contact.phoneNumber}
                      onChange={(e) => handleInputChange(e, 'contact')}
                      className='bg-[#121212]'
                      placeholder='Phone Number'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Passport Information */}
          <div className='col-span-12 border-b-2 border-white pb-10'>
            <div className='grid grid-cols-12 gap-y-5'>
              <div className='col-span-12 font-semibold text-xl text-white'>
                Passport Information
              </div>
              <div className='col-span-12'>
                <div className='grid grid-cols-12 gap-5'>
                  <div className='col-span-6'>
                    <LabelInput
                      name='fullName'
                      label='Full Name'
                      value={formData.passport.fullName}
                      onChange={(e) => handleInputChange(e, 'passport')}
                      className='bg-[#121212]'
                      placeholder='John Doe'
                    />
                  </div>
                  <div className='col-span-6'>
                    <LabelInput
                      name='passportNumber'
                      label='Passport Number'
                      value={formData.passport.passportNumber}
                      onChange={(e) => handleInputChange(e, 'passport')}
                      className='bg-[#121212]'
                      placeholder='12345'
                    />
                  </div>
                  <div className='col-span-6'>
                    <div className='flex flex-col gap-y-3 relative'>
                      <label className='inputLabel text-white'>Date of Birth</label>
                      <DatePicker
                        selected={formData.passport.dob}
                        onChange={(date) => handleDateChange(date, 'dob', 'passport')}
                        className='w-full relative cursor-pointer bg-transparent border py-3 font-manrope rounded-lg text-white px-2 border-borderInput focus:outline-primaryGreen focus:outline-none focus:border-0'
                        dateFormat="MM/dd/yyyy"
                      />
                      <MdOutlineDateRange size={20} className='text-white/80 cursor-pointer absolute right-3 top-[3.2rem]' />
                    </div>
                  </div>
                  <div className='col-span-6'>
                    <LabelInput
                      name='nationality'
                      label='Nationality'
                      value={formData.passport.nationality}
                      onChange={(e) => handleInputChange(e, 'passport')}
                      className='bg-[#121212]'
                      placeholder='USA'
                    />
                  </div>
                  <div className='col-span-6'>
                    <div className='flex flex-col gap-y-3 relative'>
                      <label className='inputLabel text-white'>Date of Issue</label>
                      <DatePicker
                        selected={formData.passport.dateOfIssue}
                        onChange={(date) => handleDateChange(date, 'dateOfIssue', 'passport')}
                        className='w-full relative cursor-pointer bg-transparent border py-3 font-manrope rounded-lg text-white px-2 border-borderInput focus:outline-primaryGreen focus:outline-none focus:border-0'
                        dateFormat="MM/dd/yyyy"
                      />
                      <MdOutlineDateRange size={20} className='text-white/80 cursor-pointer absolute right-3 top-[3.2rem]' />
                    </div>
                  </div>
                  <div className='col-span-6'>
                    <div className='flex flex-col gap-y-3 relative'>
                      <label className='inputLabel text-white'>Date of Expiry</label>
                      <DatePicker
                        selected={formData.passport.dateOfExpiry}
                        onChange={(date) => handleDateChange(date, 'dateOfExpiry', 'passport')}
                        className='w-full relative cursor-pointer bg-transparent border py-3 font-manrope rounded-lg text-white px-2 border-borderInput focus:outline-primaryGreen focus:outline-none focus:border-0'
                        dateFormat="MM/dd/yyyy"
                        minDate={new Date()}
                      />
                      <MdOutlineDateRange size={20} className='text-white/80 cursor-pointer absolute right-3 top-[3.2rem]' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-12 text-white hover:text-black cursor-pointer bg-[#1d1d1d]  font-semibold px-3 rounded-md h-24 grid items-center hover:bg-white transition-all duration-300 ease-linear">
            <div className="grid grid-cols-12 items-center">
              <div className="col-span-6">
                <div className="grid grid-cols-12 items-center gap-x-3">
                  <div className="col-span-5">
                    <div className="flex items-center gap-x-3">
                      <div className="h-9 w-9 relative flex-shrink-0">
                        <img
                          src={`${StorageURL}/${selectedUserMatch?.team1?.logo}`}
                          className="absolute h-full w-full object-contain"
                        />
                      </div>
                      <span className='text-xs'>{selectedUserMatch?.team1?.name}</span>
                    </div>
                  </div>
                  <div className="col-span-1">
                    <div className="flex items-center justify-center">
                      <span>V</span>
                      <span className="h-16 bg-[#343434] w-[1px]"></span>
                      <span>S</span>
                    </div>
                  </div>
                  <div className="col-span-5">
                    <div className="flex items-center justify-end gap-x-3">
                      <div className="h-9 w-9 relative flex-shrink-0">
                        <img
                          src={`${StorageURL}/${selectedUserMatch?.team2?.logo}`}
                          className="absolute h-full w-full object-contain"
                        />
                      </div>
                      <span className="text-xs">
                        {selectedUserMatch?.team2?.name}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-6">
                <div className="grid grid-cols-12 items-center">
                  <div className="col-span-9">
                    <div className="flex items-center gap-x-3 flex-shrink-0 ">
                      <TbClockHour12 color="white" size={24} />
                      <span className='text-sm flex gap-x-3'>
                        <span>
                          {convertToDateFormat(selectedUserMatch.date)}
                        </span>
                        <span>
                          {convertToAmPm(selectedUserMatch.time)}
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="col-span-3">
                    <div className="flex items-center gap-x-3 flex-shrink-0 ">
                      <GoLocation color="white" size={20} />
                      <span className='text-sm'>{selectedUserMatch.venue}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-span-12 bg-[#1d1d1d]  p-5 rounded-lg'>
            <div className='grid grid-cols-12 gap-y-5'>
              <div className='col-span-12 '>
                <div className='flex justify-between'>
                  <span className='font-semibold text-xl text-white'>
                    {selectedUserPackage.name}
                  </span>
                  <h2 className='text-white font-semibold text-xl'>${selectedUserPackage.price}</h2>
                </div>
              </div>
              <div className='col-span-12 text-white text-sm'>
                {selectedUserPackage.description}
              </div>
            </div>
          </div>
          <div className='col-span-12'>
            <PrimaryButton onClick={() => handleBooking()} size='medium'>{loading2 ? "Booking order..." : "Book an Order"}</PrimaryButton>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-y-10 relative">
          <div className="col-span-12">
            <SecondaryButton />
          </div>
          <div className="col-span-12">
            <div className="flex justify-between items-center">
              <div className="headerText capitalize">cart details</div>
              <PrimaryButton size="medium" onClick={handleCheckout}>
                Proceed to Checkout
              </PrimaryButton>
            </div>
          </div>
          <div className="col-span-12">
            <div className="grid grid-cols-12 gap-x-5">
              <div className="lg:col-span-5 col-span-12 bg-[#161616] text-[#464646] rounded-2xl p-6">
                <div className="flex flex-col">
                  <div className="flex flex-col lg:gap-x-8 gap-y-8">
                    <div className="flex flex-col gap-5">
                      <h1 className="text-3xl font-bold text-white">
                        {selectedUserPackage.name}
                      </h1>
                      <p className="text-sm w-11/12 text-secondaryText">
                        {selectedUserPackage.description}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-7 p-10 rounded-xl text-black bg-gradient-to-r from-[#88F67E] to-[#FFEC45]">
                <div className="grid grid-cols-12 gap-y-5">
                  <div className="col-span-12">
                    <div className="flex gap-x-5 justify-center">
                      <div className="rounded-full h-16 w-16 bg-black/20 relative flex justify-center items-center">
                        <img
                          src="/assets/images/userdashboard/event-logo2.png"
                          width={30}
                        />
                      </div>
                      <div className="rounded-full h-16 w-16 bg-black/20 relative flex justify-center items-center">
                        <img
                          src="/assets/images/userdashboard/event-logo2.png"
                          width={30}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12">
                    <div className="grid grid-cols-12 gap-y-3">
                      <div
                        className={`col-span-12 font-extrabold text-3xl text-center text-black`}
                      >
                        {selectedUserMatch?.team1?.name}
                        <span className='text-3xl px-4 font-semibold'>VS</span>
                        {selectedUserMatch?.team2?.name}
                      </div>
                      <div className="col-span-12">
                        <div className="grid grid-cols-12 items-center gap-x-3">
                          <div className="col-span-4 text-sm grid gap-y-3">
                            <div className="flex gap-x-3 items-center">
                              <svg
                                className={`stroke-black`}
                                width="22"
                                height="22"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M12 6V12L16 14"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                              <span className='font-semibold'>
                                {convertToAmPm(selectedUserMatch.time)}
                              </span>
                            </div>
                            <div className="flex gap-x-3 items-center">
                              <svg
                                className={`stroke-black`}
                                width="22"
                                height="22"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M21 10C21 17 12 23 12 23C12 23 3 17 3 10C3 7.61305 3.94821 5.32387 5.63604 3.63604C7.32387 1.94821 9.61305 1 12 1C14.3869 1 16.6761 1.94821 18.364 3.63604C20.0518 5.32387 21 7.61305 21 10Z"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                              <p className='font-semibold'>{selectedUserMatch.venue}</p>
                            </div>
                          </div>
                          <div className="col-span-4 text-sm grid justify-center">
                            <div className='flex gap-x-5'>
                              <span
                                className={`border-b-2 border-black w-fit flex gap-x-2 items-center font-semibold`}
                              >
                                Request a Quote <FaArrowRight />
                              </span>
                            </div>
                          </div>
                          <div className="col-span-4">
                            <div className="flex justify-end">
                              <div className='w-20 h-20 relative'>
                              <img 
                                src={`${StorageURL}/${selectedUserMatch?.category?.logo}`}
                                width={100}
                                className="flex-shrink-0 object-contain absolute h-full w-full"
                                />
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-12 text-white text-sm">
            <div className="grid grid-cols-12">
              {specificKeys.map((item, index) => {
                let data = selectedUserPackage[item];
                return (
                  data != '' && (
                    <div
                      key={index}
                      className="col-span-12 border-t border-borderInput py-5"
                    >
                      <div className="flex justify-between items-center">
                        <div className="flex flex-col gap-y-3">
                          <div className="primaryText">
                            {Features[index]}
                          </div>
                          <div className="text-[#5C5C5C]">{data} </div>
                        </div>
                        <div>
                          <img
                            src="/assets/images/svgs/package/tick.svg"
                            width={50}
                          />
                        </div>
                      </div>
                    </div>
                  )
                );
              })}
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default PackageDetails;
