import React, { useEffect, useMemo, useState } from 'react';
import { useAppContext } from '../../../UseContext/ContextProvider';
import { Table } from '../../../Components/ReactTable/Table';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { axiosInstance, URL } from '../../../utilities/ConstantData';
import Loader from '../../../Components/UiElements/Loader';
import { PrimaryButton } from '../../../Components/UiElements/Buttons';
import UserModal from '../../../Components/Modal/User/NewUserModal';
import { LuUserX2 } from "react-icons/lu";
import { MdCalendarMonth } from 'react-icons/md';
import { TbCalendarWeek } from "react-icons/tb";
import { TbUsers } from "react-icons/tb";
import CountUp from 'react-countup';

const UserManagement = () => {
  const { handleErrors, showBackdropWithContent } = useAppContext();
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [statistics, setStatistics] = useState([]);
  const [newUser, setNewUser] = useState();
  const navigate = useNavigate();

  const iconsArray = [
    <TbCalendarWeek size={23} color="#000000" className='font-bold' />,
    <MdCalendarMonth size={23} color="#000000" className='font-bold'/>,
    <TbUsers size={23} color="#000000" className='font-bold'/>,
    <LuUserX2 size={23} color="#000000" className='font-bold' />
  ];

  useEffect(() => {
    fetchData();
  }, [newUser]);

  const fetchData = async () => {
    try {
      const res = await axiosInstance().get(`${URL}/all-users`);
      setLoading(false);
      const data = res.data.data;
      const filteredData = data.filter(item => item.userType != 'ap%4k45a5sd')
      const sortedData = filteredData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setAllUsers(sortedData);
      setStatistics(res.data.statistics)
    } catch (error) {
      setLoading(false);
      const errors = error?.response?.data?.errors;
      const statusCode = error?.response?.status;
      if (statusCode == 401) {
        toast.error(errors);
        try {
          localStorage.removeItem('MH_UserData')
        } catch (error) {
          console.log(error)
        } finally {
          navigate("/Login")
        }
      } else {
        handleErrors(error);
      }
    }
  };

  const updateStatics = (status) => {
    setStatistics(prevStats =>
      prevStats.map(stat =>
        stat.title === 'Deactivated Users'
          ? { ...stat, numberOfUsers: status == "active" ? stat.numberOfUsers + 1 : stat.numberOfUsers - 1 }
          : stat
      )
    )
  }

  const handleToggleStatus = async (userId, currentStatus) => {
    setLoading2(true);
    const newStatus = currentStatus === 'active' ? 'deactive' : 'active';
    const updateStatusRequest = axiosInstance().patch(`${URL}/change-user-status/${userId}`, { status: newStatus });
    toast.promise(
      updateStatusRequest,
      {
        pending: `Updating user status...`,
        success: `User ${newStatus}d successfully!`,
        error: (error) => {
          const errors = error?.response?.data?.errors;
          const statusCode = error?.response?.status;
          if (statusCode === 401) {
            try {
              localStorage.removeItem('MH_UserData');
            } catch (error) {
              console.log(error);
            } finally {
              navigate("/Login");
            }
            return errors || 'Unauthorized access. Redirecting to login.';
          } else {
            handleErrors(error);
            return errors || `An error occurred while updating the user status to ${newStatus}`;
          }
        }
      }
    ).then(() => {
      setLoading2(false);
      setAllUsers(prevUsers =>
        prevUsers.map(user =>
          user._id === userId ? { ...user, status: newStatus } : user
        )
      );
      updateStatics(currentStatus);
    }).catch((error) => {
      setLoading2(false);
      console.error(error);
    });
  };


  const handleDetails = (id) =>{
    navigate(`/user/${id}`)
  }

  const cols = useMemo(() => [
    {
      header: 'Name',
      cell: (row) => row.renderValue() || 'N/A',
      accessorKey: 'userName',
    },
    {
      header: 'Email',
      cell: (row) => (
        <span>
          {row.renderValue()}
        </span>
      ),
      accessorKey: 'email',
    },
    {
      header: 'Phone',
      cell: (row) => (
        <span>
          {row.renderValue() || 'N/A'}
        </span>
      ),
      accessorKey: 'phone',
    },
    // {
    //   header: 'Last Bought Package',
    //   cell: 'Silver Package',
    //   accessorKey: 'package',
    // },
    // {
    //   header: 'Last Payment Date',
    //   cell: (row) => (
    //     <span>
    //       05/25/2024
    //     </span>
    //   ),
    //   accessorKey: 'date',
    // },
    {
      header: 'Status',
      cell: (row) => (
        <div className="flex items-center gap-x-5 ps-24">
          <label className="inline-flex items-center cursor-pointer">
            <input
              checked={row.getValue('status') === 'active'}
              type="checkbox"
              disabled={loading2}
              className="sr-only peer"
              onChange={() => handleToggleStatus(row.row.original._id, row.getValue('status'))}
            />
            <div className="relative w-8 h-4 py-2 bg-white/90 peer-focus:outline-none rounded-full after:bg-red-600 peer-checked:after:translate-x-3 peer-checked:after:bg-primaryGreen after:content-[''] after:absolute after:top-[1.99px] after:start-[4px] after:rounded-full after:h-3 after:w-3 after:transition-all"></div>
            <span className="ms-4 text-base text-white/80">{row.getValue('status') === 'active' ? 'Active' : 'Deactivated'}</span>
          </label>
        </div>
      ),
      accessorKey: 'status',
    },
    {
      header: 'Details',
      cell: (row)=>(
        <PrimaryButton color='black' size='small' className='bg-[#202125] mt-1' onClick={()=>handleDetails(row.row.original._id)}>View Details</PrimaryButton>
      ),
      accessorKey: ('View Details'),
    },
  ], []);

  const handleBackdrop = () => {
    navigate('/create-user')
    // const content = (
    //   <UserModal setNewUser={setNewUser} />
    // )
    // showBackdropWithContent(content)
  }

  return loading ? <Loader /> : (
    <div className='grid grid-cols-12 text-white xl:gap-x-10 gap-y-8'>
      <div className='col-span-12'>
        <div className='grid grid-cols-12 xl:gap-x-8 gap-y-8'>
          <div className='col-span-12'>
            <div className='flex justify-between items-center'>
              <div className='headerText'>
                Users Management
              </div>
              <PrimaryButton size='medium' onClick={handleBackdrop}>Create New User</PrimaryButton>
            </div>
          </div>
          <div className='col-span-12'>
            <div className='grid grid-cols-12 xl:gap-x-5 gap-y-5'>
              {statistics.map((item, index) => (
                <div key={index} className='xl:col-span-3 md:col-span-6 col-span-12 rounded-2xl bg-cardBG border-primaryBorder border-[1px] p-7'>
                  <div className='grid grid-cols-12'>
                    <div className='col-span-10'>
                      <div className='flex flex-col gap-3'>
                        <span className='text-base font-semibold'>{item.title}</span>
                        <span className='text-4xl font-semibold '><CountUp end={item.numberOfUsers}/></span>
                      </div>
                    </div>
                    <div className='grid col-span-2 items-end justify-end'>
                      <div className='h-9 w-10 rounded-lg bg-primaryGreen flex justify-center items-center'>
                        {iconsArray[index % iconsArray.length]}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className='col-span-12'>
        <div className='grid grid-cols-12 gap-5 xl:gap-x-8 gap-y-8'>
          {/* <div className='col-span-12 headerText'>
            All Users
          </div> */}
          <div className='col-span-12'>
            <Table data={allUsers} columns={cols} showNavigation search={true} header='All Users' />
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserManagement;
