// UserDashboard.js
import React, { useEffect, useState } from 'react';
import CustomSlider from '../../../Components/Slider/CustomSlider';
import { useAppContext } from '../../../UseContext/ContextProvider';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import {
  axiosInstance,
  StorageURL,
  URL,
} from '../../../utilities/ConstantData';
import Loader from '../../../Components/UiElements/Loader';
import Tabs from '../../../Components/Tabs/Tabs';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { TbClockHour12 } from 'react-icons/tb';
import { GoLocation } from 'react-icons/go';
import { FiArrowRight } from 'react-icons/fi';
const UserDashboard = () => {
  const {
    categoryData,
    setCategoryData,
    handleErrors,
    setSelectedEvent,
    convertToAmPm,
    setselectedUserMatch,
    convertToDateFormat,
    setSearchTxt,
  } = useAppContext();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [matchesData, setMatchesData] = useState([]);
  const [upcomingMatches, setUpcomingMatches] = useState([]);
  const [trendingMatches, setTrendingMatches] = useState([]);

  useEffect(() => {
    fetchMatches();
    fetchCategories();
    setSearchTxt('');
  }, []);

  const fetchMatches = async () => {
    axiosInstance()
      .get(`${URL}/event/all`)
      .then((res) => {
        const data = res.data.data;
        const filteredData = data.filter(
          (item) =>
            item?.category?.status != 'inactive' &&
            item?.category?.status &&
            item?.team1?.status != 'inactive' &&
            item?.team1?.status &&
            item?.team2?.status != 'inactive' &&
            item?.team2?.status
        );
        const sortedData = filteredData.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setMatchesData(sortedData);
        calculateUpcomingMatch(sortedData);
        calculateTrendingMathces(sortedData);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        const errors = error?.response?.data?.errors;
        const statusCode = error?.response?.status;
        if (statusCode == 401) {
          toast.error(errors);
          try {
            localStorage.removeItem('MH_UserData');
          } catch (error) {
            console.log(error);
          } finally {
            navigate('/Login');
          }
        } else {
          handleErrors(error);
        }
      });
  };

  const calculateUpcomingMatch = (filteredData) => {
    const upcoming = filteredData.filter((item) => {
      const matchDate = new Date(item.date);
      const today = new Date();
      const next30Days = new Date();
      next30Days.setDate(today.getDate() + 30);
      return matchDate >= today && matchDate <= next30Days;
    });
    setUpcomingMatches(upcoming);
  };

  const calculateTrendingMathces = (filteredData) => {
    const trending = filteredData.filter((item) => {
      const matchDate = new Date(item.date);
      const today = new Date();
      const next7Days = new Date();
      next7Days.setDate(today.getDate() + 7);
      return matchDate >= today && matchDate <= next7Days;
    });
    setTrendingMatches(trending);
  };

  const handleMatch = (data) => {
    navigate('/select-package');
    setselectedUserMatch(data);
  };

  const fetchCategories = async () => {
    axiosInstance()
      .get(`${URL}/category/all`)
      .then((res) => {
        setLoading(false);
        const data = res.data.data;
        const filteredData = data.filter(
          (item) => item.status != 'inactive'
        );
        const sortedData = filteredData.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setCategoryData(sortedData);
      })
      .catch((error) => {
        setLoading(false);
        const errors = error?.response?.data?.errors;
        const statusCode = error?.response?.status;
        if (statusCode == 401) {
          toast.error(errors);
          try {
            localStorage.removeItem('MH_UserData');
          } catch (error) {
            console.log(error);
          } finally {
            navigate('/Login');
          }
        } else {
          handleErrors(error);
        }
      });
  };

  const handleSelectedItem = (item) => {
    setSelectedEvent(item);
    navigate(
      `/events/${item.name.replace(/\s+/g, '').toLowerCase()}`
    );
  };

  const Slide = ({ data }) => {
    return (
      <div
        onClick={() => handleMatch(data)}
        className="border border-borderInput hover:cursor-pointer hover:border-primaryGreen transition-opacity rounded-lg p-5 grid grid-cols-12 text-white items-center"
      >
        <div className="col-span-12 text-sm">
          <div className="grid grid-cols-12 items-center">
            <div className="col-span-4">
              <div className="flex flex-col gap-y-2 items-center">
                <div className="h-16 w-16 relative">
                  <img
                    src={`${StorageURL}/${data?.team1?.logo}`}
                    className="absolute h-full w-full object-cover"
                  />
                </div>
                <div>{data?.team1?.name}</div>
              </div>
            </div>
            <div className="col-span-4">
              <div className="flex flex-col gap-y-2 items-center text-center">
                <div>{data?.category?.name || ''}</div>
                <div>VS</div>
                <div className="flex gap-x-1 items-center">
                  <div className="h-2 w-2 bg-red-500 rounded-full"></div>
                  <div>{convertToAmPm(data.time)}</div>
                </div>
                <div className="text-center">
                  {convertToDateFormat(data.date)}
                </div>
              </div>
            </div>
            <div className="col-span-4">
              <div className="flex flex-col gap-y-2 items-center">
                <div className="h-16 w-16 relative">
                  <img
                    src={`${StorageURL}/${data?.team2?.logo}`}
                    className="absolute h-full w-full"
                  />
                </div>
                <div>{data?.team2?.name}</div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className='col-span-4'>
          <PrimaryButton size='small' onClick={() => handleMatch(data)} className='text-[10px] text-nowrap'>
            Select Package
          </PrimaryButton>
        </div> */}
      </div>
    );
  };

  const slides =
    categoryData.length > 0 &&
    categoryData.map((item, index) => (
      <div
        key={index}
        className="min-h-56 rounded-xl bg-cover bg-center border border-borderInput flex items-end p-5"
        style={{
          backgroundImage: `url("${StorageURL}/${item.banner_image}")`,
        }}
      >
        <div className="flex items-end h-full text-white justify-between w-full">
          <div className="flex flex-col gap-y-5">
            {item.logo && (
              <img
                src={`${StorageURL}/${item.logo}`}
                alt="logo"
                width={40}
              />
            )}
            <div>
              <p className="font-semibold text-lg">{item.name}</p>
              <p
                className="w-2/4 text-sm truncate-multiline"
                style={{ '--line-clamp': 4 }}
              >
                {item.description}
              </p>
            </div>
          </div>
          <div
            onClick={() => handleSelectedItem(item)}
            className="h-6 w-6 cursor-pointer flex-shrink-0 rounded-full bg-primaryGreen flex justify-center items-center  shadow-primaryGreen shadow-sm"
          >
            <svg
              width="12"
              height="10"
              viewBox="0 0 12 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.3333 5.00912L6.61508 9.88886H5.04037L9.18868 5.60689H0.222229V4.39306H9.18868L5.04037 0.111084H6.61508L11.3333 5.00912Z"
                fill="black"
              />
            </svg>
          </div>
        </div>
      </div>
    ));

  const UpcomingSlides =
    upcomingMatches.length > 0 &&
    upcomingMatches.map((item, index) => {
      return <Slide data={item} key={index} />;
    });

  const TrendingSlide =
    trendingMatches.length > 0 &&
    trendingMatches.map((item, index) => {
      return <Slide data={item} key={index} />;
    });

  const isSingleSlide = slides.length === 1;

  return loading ? (
    <Loader />
  ) : (
    <div className="grid grid-cols-12 gap-y-10">
      {categoryData.length > 0 && (
        <div className="col-span-12">
          <CustomSlider
            header="Upcoming Events"
            slides={slides}
            slidesToShow={3}
            slidesToScroll={1}
            infinite={false}
            speed={500}
            centerMode={false}
            responsive={[
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                  centerMode: true,
                  centerPadding: '10px',
                },
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  centerMode: false,
                  centerPadding: '0px',
                },
              },
            ]}
          />
        </div>
      )}
      {categoryData.length > 0 && (
        <div className="col-span-12">
          <Tabs tabs={categoryData} matchesData={matchesData} />
        </div>
      )}
      <div className="col-span-12">
        <div className="grid grid-cols-12 gap-x-5">
          <div className="col-span-4 text-white">
            <div className="grid grid-cols-12 cursor-pointer gap-y-5">
              {categoryData.length > 0 &&
                categoryData.slice(0, 3).map((item, index) => {
                  return (
                    <div onClick={() => handleSelectedItem(item)} key={index} className="col-span-12 px-3 h-20 rounded-md grid items-center">
                      <div className="flex gap-x-5 items-center">
                        <div className="h-12 w-12 relative flex-shrink-0">
                          <img
                            src={`${StorageURL}/${item?.logo}`}
                            className="absolute h-full w-full object-contain"
                          />
                        </div>
                        <div className="flex justify-between w-full items-center">
                          <div className="flex flex-col">
                            <p className="text-md capitalize font-semibold">
                              {item?.name}
                            </p>
                            <p style={{ '--line-clamp': 2 }} className="text-xs truncate-multiline text-[#aaaaaa]">{item?.description}</p>
                          </div>
                          <MdKeyboardArrowRight
                            size={30}
                            color="white"
                          />
                        </div>
                      </div>
                    </div>
                  )
                })}
            </div>
          </div>
          <div className="col-span-8">
            <div className="grid grid-cols-12 group gap-y-5">
              {matchesData.length > 0 &&
                matchesData.slice(0, 3).map((item, index) => {
                  return (
                    <div onClick={() => handleMatch(item)} key={index} className="col-span-12 text-sm text-white hover:text-black cursor-pointer bg-black font-semibold px-5 rounded-md h-20 grid items-center hover:bg-white transition-all duration-300 ease-linear">
                      <div className="grid grid-cols-12 items-center">
                        <div className="col-span-5">
                          <div className="grid grid-cols-12 items-center">
                            <div className="col-span-5">
                              <div className="flex items-center gap-x-3">
                                <div className="h-10 w-10 relative flex-shrink-0">
                                  <img
                                    src={`${StorageURL}/${item?.team1?.logo}`}
                                    className="absolute h-full w-full object-contain"
                                  />
                                </div>
                                <span>{item?.team1?.name}</span>
                              </div>
                            </div>
                            <div className="col-span-2">
                              <div className="flex items-center">
                                <span>V</span>
                                <span className="h-16 bg-[#343434] w-[1px]"></span>
                                <span>S</span>
                              </div>
                            </div>
                            <div className="col-span-5 pl-4">
                              <div className="flex items-center gap-x-3">
                                <div className="h-10 w-10 relative flex-shrink-0">
                                  <img
                                    src={`${StorageURL}/${item?.team2?.logo}`}
                                    className="absolute h-full w-full object-contain"
                                  />
                                </div>
                                <span className="font-semibold">
                                  {item?.team2?.name}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-span-5 grid justify-center">
                          <div className="grid grid-cols-12 gap-x-4">
                            <div className="col-span-8">
                              <div className="flex items-center gap-x-2 flex-shrink-0 ">
                                <TbClockHour12
                                  size={24}
                                />
                                 <div className='gap-x-3 flex justify-center items-center'>
                                  <span>
                                    {convertToDateFormat(item.date)}
                                  </span>
                                  <span className='text-nowrap'>
                                    {convertToAmPm(item.time)}
                                  </span>

                                 </div>
                              </div>
                            </div>
                            <div className="col-span-4">
                              <div className="flex items-center gap-x-3 flex-shrink-0 ">
                                <GoLocation size={20} />
                                <span>{item.venue}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-span-2 pl-3">
                          <div className="flex justify-between items-center">
                            {/* <BsFillHeartFill
                              className="text-[#f36368]"
                              size={24}
                            /> */}
                            <div className="flex gap-x-3 items-center">
                              <span>View Details</span>
                              <FiArrowRight color="red" size={24} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDashboard;
