import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import NotFound from './Pages/404/NotFound';
import Layout from './ThemeLayout/Layout';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PackageInformation from './Pages/UserPackages/FilterMatches';
import CreatePackage from './Pages/Packages/CreatePackage';
import Account from './Pages/Packages/Account';
import Login from './Pages/Authentication/Login';
import Signup from './Pages/Authentication/Signup';
import FinishSignup from './Pages/Authentication/FinishSignup';
import ForgotPassword from './Pages/Authentication/ForgotPassword';
import VerifyCode from './Pages/Authentication/VerifyCode';
import ConfirmPassword from './Pages/Authentication/ConfirmPassword';
import AdminDashboard from './Pages/Dashboard/Admin/AdminDashboard';
import UserDashboard from './Pages/Dashboard/User/UserDashboard';
import UserManagement from './Pages/Dashboard/Admin/UserManagement';
import AllCategories from './Pages/Categories/AllCategories';
import CreateCategory from './Pages/Categories/CreateCategory';
import EditCategory from './Pages/Categories/EditCategory';
import AllMatches from './Pages/Matches/AllMatches';
import CreateMatch from './Pages/Matches/CreateMatch';
import AllTeams from './Pages/Teams/AllTeams';
import AllPackages from './Pages/Packages/AllPackages';
import NewPackage from './Pages/Packages/NewPackage';
import ProtectedRoute from './ProtectedRoutes';
import { AdminProfile } from './Pages/Profile/AdminProfile';
import PackageDetails from './Pages/UserPackages/PackageDetails';
import Events from './Pages/UserPackages/Events';
import SelectPackage from './Pages/UserPackages/SelectPackage';
import FilterMatches from './Pages/UserPackages/FilterMatches';
import ViewDetails from './Pages/UserDetails/ViewDetails';
import CreateUser from './Pages/Dashboard/Admin/CreateUser';
import Payment from './Pages/Payment/Payment';
import EventDetail from './Pages/Tickets/EventDetail';
import Tickets from './Pages/Tickets/Tickets';
import UserPayment from './Pages/Payment/UserPayment';
import ProfileSettings from './Pages/Profile/ProfileSettings';

const routes = [
  { path: "/", element: <Navigate to="/login" replace />, type: "public" },
  { path: "/login", element: <Login />, type: "public" },
  { path: "/signup", element: <Signup />, type: "public" },
  { path: "/finish-signup", element: <FinishSignup />, type: "public" },
  { path: "/forgot-password", element: <ForgotPassword />, type: "public" },
  { path: "/verify-code", element: <VerifyCode />, type: "public" },
  { path: "/confirm-password", element: <ConfirmPassword />, type: "public" },
  {
    path: "/",
    element: <Layout />,
    type: "protected",
    children: [
      { path: "admin/dashboard", element: <AdminDashboard />, type: "admin" },
      { path: "all-users", element: <UserManagement />, type: "admin" },
      { path: "matches", element: <FilterMatches />, type: "user" },
      { path: "create-package", element: <CreatePackage />, type: "admin" },
      { path: "admin-account", element: <Account />, type: "admin" },
      { path: "all-events", element: <AllCategories />, type: "admin" },
      { path: "create-event", element: <CreateCategory />, type: "admin" },
      { path: "edit-event", element: <EditCategory />, type: "admin" },
      { path: "all-matches", element: <AllMatches />, type: "admin" },
      { path: "match/:action", element: <CreateMatch />, type: "admin" },
      { path: "all-teams", element: <AllTeams />, type: "admin" },
      { path: "all-packages", element: <AllPackages />, type: "admin" },
      { path: "package/:action", element: <NewPackage />, type: "admin" },
      { path: "dashboard", element: <UserDashboard />, type: "user" },
      { path: "profile", element: <AdminProfile />, type: "both" },
      { path: "events/:event", element: <Events />, type: "user" },
      { path: "package-details", element: <PackageDetails />, type: "user" },
      { path: "select-package", element: <SelectPackage />, type: "user" },
      { path: "payment", element: <Payment />, type: "user" },
      { path: "tickets", element: <Tickets />, type: "user" },
      { path: "tickets-details", element: <EventDetail />, type: "user" },
      { path: "payments", element: <UserPayment/>, type: "user" },
      { path: "profile-settings", element: <ProfileSettings/>, type: "user" },
      { path: "user/:id", element: <ViewDetails />, type: "admin" },
      { path: "create-user", element: <CreateUser />, type: "admin" },
    ],  
  },
  { path: "/*", element: <NotFound />, type: "public" },
];

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        {routes.map(({ path, element, type, children }) => {
          if (children) {
            return (
              <Route
                key={path}
                path={path}
                element={<ProtectedRoute type={type}>{element}</ProtectedRoute>}
              >
                {children.map(({ path, element, type }) => (
                  <Route
                    key={path}
                    path={path}
                    element={<ProtectedRoute type={type}>{element}</ProtectedRoute>}
                  />
                ))}
              </Route>
            );
          }

          return (
            <Route
              key={path}
              path={path}
              element={<ProtectedRoute type={type}>{element}</ProtectedRoute>}
            />
          );
        })}
      </Routes>
      <ToastContainer autoClose={1500} theme="dark" hideProgressBar={true} />
    </BrowserRouter>
  );
};

export default App;
