import React, { useRef, useState, useCallback, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FaArrowLeftLong, FaArrowRightLong } from 'react-icons/fa6';

const CustomSlider = ({ slides, slidesToShow = 3, slidesToScroll = 1, infinite = false, speed = 500, responsive = [], header }) => {
    const sliderRef = useRef(null);
    const [isPrevDisabled, setIsPrevDisabled] = useState(true);
    const [isNextDisabled, setIsNextDisabled] = useState(false);
    const [isSingleSlide, setIsSingleSlide] = useState(false);

    const handleBeforeChange = useCallback((current, next) => {
        const totalSlides = slides.length;
        if (totalSlides <= slidesToShow) {
            setIsPrevDisabled(true);
            setIsNextDisabled(true);
            setIsSingleSlide(totalSlides === 1);
        } else {
            setIsPrevDisabled(next === 0);
            setIsNextDisabled(next >= totalSlides - slidesToShow);
            setIsSingleSlide(false);
        }
    }, [slides.length, slidesToShow]);

    const handleAfterChange = useCallback((index) => {
        const totalSlides = slides.length;
        if (totalSlides <= slidesToShow) {
            setIsPrevDisabled(true);
            setIsNextDisabled(true);
            setIsSingleSlide(totalSlides === 1);
        } else {
            setIsPrevDisabled(index === 0);
            setIsNextDisabled(index >= totalSlides - slidesToShow);
            setIsSingleSlide(false);
        }
    }, [slides.length, slidesToShow]);

    useEffect(() => {
        handleBeforeChange(0, 0);
    }, [handleBeforeChange]);

    const settings = {
        infinite,
        speed,
        slidesToShow,
        slidesToScroll,
        arrows: false,
        beforeChange: handleBeforeChange,
        afterChange: handleAfterChange,
        responsive,
    };

    const handlePrevClick = () => {
        if (!isPrevDisabled) {
            sliderRef.current.slickPrev();
        }
    };

    const handleNextClick = () => {
        if (!isNextDisabled) {
            sliderRef.current.slickNext();
        }
    };

    return (
        <div className={`relative ${isSingleSlide ? 'single-slide' : ''}`}>
            <div className='flex justify-between items-center pb-8 ml-3'>
                {typeof header != 'string' ?
                    <div>{header}</div>
                    :
                    <div className='headerText'>{header}</div>
                }
                <div className='flex justify-end items-center'>
                    <div
                        className={`p-2 cursor-pointer ${isPrevDisabled ? 'opacity-50 cursor-not-allowed text-gray-400' : 'text-primaryGreen'}`}
                        onClick={handlePrevClick}
                    >
                        <FaArrowLeftLong className={`text-xl ${isPrevDisabled ? 'text-gray-400' : 'text-primaryGreen'}`} />
                    </div>
                    <div
                        className={`p-2 cursor-pointer ${isNextDisabled ? 'opacity-50 cursor-not-allowed text-gray-400' : 'text-primaryGreen'}`}
                        onClick={handleNextClick}
                    >
                        <FaArrowRightLong className={`text-xl ${isNextDisabled ? 'text-gray-400' : 'text-primaryGreen'}`} />
                    </div>
                </div>
            </div>
            <Slider ref={sliderRef} {...settings}>
                {slides.map((slide, index) => (
                    <div key={index} className='px-2'>
                        {slide}
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default CustomSlider;
