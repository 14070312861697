import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../UseContext/ContextProvider';
import { PrimaryButton } from '../../Components/UiElements/Buttons';
import { MdOutlineEdit } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import EditTeamModal from '../../Components/Modal/Team/EditTeamModal';
import DeleteModal from '../../Components/Modal/DeleteModal';
import { axiosInstance, StorageURL, URL } from '../../utilities/ConstantData';
import Loader from '../../Components/UiElements/Loader';
import { toast } from 'react-toastify';
import CreateTeamModal from '../../Components/Modal/Team/CreateTeamModal';
const AllTeams = () => {
  const { showBackdropWithContent, setCategoryData, handleErrors, closeModal } = useAppContext()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [TeamData, setTeamData] = useState([])
  const [updation, setUpdation] = useState()
  useEffect(() => {
    fetchData()
    fetchCategories()
  }, [updation])

  const fetchData = async () => {
    axiosInstance().get(`${URL}/team/all`)
      .then((res) => {
        setLoading(false)
        const data = res.data.data;
        const filteredData = data.filter((item) => item.categoryStatus != 'inactive')
        setTeamData(filteredData)
      })
      .catch((error) => {
        setLoading(false)
        const errors = error?.response?.data?.errors
        const statusCode = error?.response?.status
        if (statusCode == 401) {
          toast.error(errors);
          try {
            localStorage.removeItem('MH_UserData')
          } catch (error) {
            console.log(error)
          } finally {
            navigate("/Login")
          }
        } else {
          handleErrors(error)
        }
      })
  };

  const fetchCategories = async () => {
    axiosInstance().get(`${URL}/category/all`)
      .then((res) => {
        const data = res.data.data;
        const filteredData = data.filter((item) => item.status != 'inactive')
        setCategoryData(filteredData)
      })
      .catch((error) => {
        const errors = error?.response?.data?.errors
        const statusCode = error?.response?.status
        if (statusCode == 401) {
          toast.error(errors);
          navigate("/Login")
        } else {
          handleErrors(error)
        }
      })
  };

  const handleEditShowBackdrop = (item) => {
    const content = (
      <EditTeamModal selectedItem={item} setUpdation={setUpdation} />
    )
    showBackdropWithContent(content)
  }

  const handleCreateBackdrop = () => {
    const content = (
      <CreateTeamModal setTeamData={setTeamData} />
    )
    showBackdropWithContent(content)
  }
  const filterCategory = (id) => {
    setTeamData(prevData => prevData.filter(team => team._id !== id));
  };


  const handleDelete = async (id) => {
    const deleteRequest = axiosInstance().delete(`${URL}/team/delete/${id}`);
    toast.promise(
      deleteRequest,
      {
        pending: 'Deleting team...',
        success: 'Team and associated matches deleted successfully',
        error: (error) => {
          const errors = error?.response?.data?.errors;
          const statusCode = error?.response?.status;

          if (statusCode === 401) {
            // Handle unauthorized error
            try {
              localStorage.removeItem('MH_UserData');
            } catch (e) {
              console.log(e);
            } finally {
              navigate("/Login");
            }
            return errors || 'Unauthorized access. Redirecting to login.';
          } else {
            handleErrors(error);
            return errors || 'An error occurred while deleting the team';
          }
        }
      }
    ).then(() => {
      filterCategory(id);
      closeModal();
    }).catch((error) => {
      console.error(error);
    });
  };

  const handleBackdrop = (id) => {
    const content = (
      <DeleteModal handleDelete={handleDelete} item="team" id={id} />
    )
    showBackdropWithContent(content)
  }

  const handleToggleStatus = async (id, status) => {
    const new_status = status === 'active' ? 'deactivated' : 'activated';
    const updateStatusRequest = axiosInstance().patch(`${URL}/team/change-status/${id}`, { status: new_status === 'activated' ? 'active' : 'inactive' });

    toast.promise(
      updateStatusRequest,
      {
        pending: `Updating team status...`,
        success: `Team ${new_status} successfully!`,
        error: (error) => {
          const errors = error?.response?.data?.errors;
          const statusCode = error?.response?.status;

          if (statusCode === 401) {
            try {
              localStorage.removeItem('MH_UserData');
            } catch (e) {
              console.log(e);
            } finally {
              navigate("/Login");
            }
            return errors || 'Unauthorized access. Redirecting to login.';
          } else {
            handleErrors(error);
            return errors || `An error occurred while updating the package status to ${new_status}`;
          }
        }
      }
    ).then(() => {
      setTeamData((prevData) => {
        return prevData.map((item) =>
          item._id === id ? { ...item, status: item.status === 'active' ? 'inactive' : 'active' } : item
        );
      });
    }).catch((error) => {
      console.error(error);
    });
  };


  return loading ? <Loader /> : (
    <div className='grid grid-cols-12 gap-y-10'>
      <div className='col-span-12 text-white'>
        <div className='flex justify-between items-center'>
          <h1 className='headerText'>All Teams</h1>
          <PrimaryButton size='medium' onClick={handleCreateBackdrop}>
            Create New Team
          </PrimaryButton>
        </div>
      </div>
      <div className='col-span-12'>
        {
          TeamData.length > 0 ?
            <div className='grid grid-cols-12 gap-7'>
              {TeamData.map((item, index) => (
                <div key={index} className={`${item.status != 'active' ? ' mix-blend-luminosity' : ''} p-5 group relative text-white grid xl:col-span-3 md:col-span-12 col-span-12 items-center rounded-xl bg-cover bg-center border-primaryBorder border-[1px] bg-gradient-to-t from-primaryGreen/10 to-transparent`}>
                  <div className="absolute top-4 right-5">
                    <div className="flex gap-x-2 text-xs">
                      <label className="inline-flex items-center cursor-pointer">
                        <input
                          checked={item.status == 'active' ? true : false}
                          type="checkbox"
                          className="sr-only peer"
                          onChange={() => handleToggleStatus(item._id, item.status)}
                        />
                        <div className="relative w-8 h-4 py-2 bg-white/90 peer-focus:outline-none rounded-full after:bg-red-600 peer-checked:after:translate-x-3 peer-checked:after:bg-primaryGreen after:content-[''] after:absolute after:top-[1.99px] after:start-[4px] after:rounded-full after:h-3 after:w-3 after:transition-all"></div>
                        <span className="ms-2 text-xs text-white/80 font-bold">
                          {item.status == 'active' ? "Active" : "Deactivated"}
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className='flex items-center gap-x-4'>
                    <div className='h-[6rem] w-[6rem] relative flex justify-center items-center'>
                      <img className='flex-shrink-0 object-scale-down absolute h-full w-full border border-borderInput/20 rounded-lg backdrop-blur-3xl' src={`${StorageURL}/${item.logo}`} alt='logo' width={70} />
                    </div>
                    <div className='flex flex-col gap-y-2'>
                      <div className='flex flex-col'>
                        <p className='text-lg font-semibold'>{item.name || ""}</p>
                        <p className='text-sm'>{item.categoryName || ""}</p>
                      </div>
                      <div className='flex items-center gap-x-3 py-1 rounded-md text-xs w-fit'>
                        <div className='flex gap-x-2 items-center border-borderInput border px-2 py-1 rounded-md cursor-pointer' onClick={() => handleEditShowBackdrop(item)}>
                          Edit <MdOutlineEdit />
                        </div>
                        <div onClick={() => handleBackdrop(item._id)} className='flex gap-x-1  items-center text-white bg-red-700 px-2 py-1 rounded-md cursor-pointer font-semibold'>
                          Delete <RiDeleteBinLine className='mt-[1px]' />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div> :
            <div>
              <h2 className='text-white text-center'>There are currently no teams available!</h2>
            </div>
        }
      </div>
    </div>
  )
}

export default AllTeams
