import React, { useState, useEffect } from 'react';
import { FaArrowRight } from 'react-icons/fa6';
import CustomSlider from '../Slider/CustomSlider';
import { useAppContext } from '../../UseContext/ContextProvider';
import { useNavigate } from 'react-router-dom';
import { StorageURL } from '../../utilities/ConstantData';

const Tabs = ({ tabs, matchesData }) => {
    const { convertToAmPm, setselectedUserMatch } = useAppContext();
    const [activeTab, setActiveTab] = useState();
    const [Tabs, setTabs] = useState(tabs);
    const [filteredMatches, setFilteredMatches] = useState([]);
    const navigate = useNavigate();

    const handleSelectedTab = (tab) => {
        setActiveTab(tab?._id || 'all');
        const today = new Date();
        let activeTabContent = matchesData.filter(item => new Date(item.date) > today);
        if (tab?.name && tab.name !== 'All') {
            activeTabContent = activeTabContent.filter(item => item?.category?._id === tab?._id);
        }
        setFilteredMatches(activeTabContent);
    };

    const handleMatch = (item) => {
        navigate('/select-package');
        setselectedUserMatch(item);
    };

    useEffect(() => {
        if (tabs && matchesData) {
            handleSelectedTab({ name: 'All' });
            removeEmptyTabs()
        }
    }, [matchesData]);

    const removeEmptyTabs = () => {
        const eventTabs = [...tabs]
        const filterTabs = eventTabs.filter((item) => {
            return checkMatchesData(item._id)
        })
        setTabs(filterTabs)
    }

    const checkMatchesData = (id) => {
        const today = new Date();
        let activeTabContent = matchesData.filter(item => new Date(item.date) > today);
        activeTabContent = activeTabContent.filter(item => item?.category?._id === id);
        return activeTabContent.length === 0 ? false : true
    }

    const getSlides = () => {
        return (
            filteredMatches.length > 0 &&
            // bg-gradient-to-r from-[#88F67E] to-[#FFEC45]
            filteredMatches.map((item, index) => (
                <div
                    key={index}
                    className={`border-2 text-white border-borderInput group grid grid-cols-12 rounded-xl bg-gradient-to-r from-[#88F67E]/0 to-[#FFEC45]/0 hover:from-[#88F67E]/100 hover:to-[#FFEC45] hover:text-black transition-all duration-300 ease-linear`}
                >
                    <div className="col-span-12 p-7">
                        <div className="grid grid-cols-12 gap-y-5">
                            <div className="col-span-12">
                                <div className="flex gap-x-5 justify-center">
                                    <div className="rounded-full h-16 w-16 bg-black/20 relative flex justify-center items-center">
                                        <img src={`${StorageURL}/${item?.team1?.logo}`} width={35} />
                                    </div>
                                    <div className="rounded-full h-16 w-16 bg-black/20 relative flex justify-center items-center">
                                        <img src={`${StorageURL}/${item?.team2?.logo}`} width={35} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-12">
                                <div className="grid grid-cols-12 gap-y-3">
                                    <div
                                        className={`col-span-12 font-extrabold text-3xl text-center`}
                                    >
                                        {item?.team1?.name}
                                        <span className='px-5'>VS</span>
                                        {item?.team2?.name}
                                    </div>
                                    <div className="col-span-12">
                                        <div className="grid grid-cols-12 items-center gap-x-3">
                                            <div className="col-span-3 text-sm grid gap-y-2">
                                                <div className="flex gap-x-3 items-center">
                                                    <svg
                                                        className={`stroke-white group-hover:stroke-black transition-all duration-300 ease-linear text-black`}
                                                        width="22"
                                                        height="22"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M12 6V12L16 14"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                    <p className='font-semibold'>{convertToAmPm(item.time)}</p>
                                                </div>
                                                <div className="flex gap-x-3 items-center">
                                                    <svg
                                                        className={`stroke-white group-hover:stroke-black transition-all duration-300 ease-linear text-black`}
                                                        width="22"
                                                        height="22"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M21 10C21 17 12 23 12 23C12 23 3 17 3 10C3 7.61305 3.94821 5.32387 5.63604 3.63604C7.32387 1.94821 9.61305 1 12 1C14.3869 1 16.6761 1.94821 18.364 3.63604C20.0518 5.32387 21 7.61305 21 10Z"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                    <p className='font-semibold'>{item.venue}</p>
                                                </div>
                                            </div>
                                            <div className="col-span-7 text-sm grid justify-center">
                                                <div className='flex gap-x-3 items-center'>
                                                    <span
                                                        className={` 
                                                             group-hover:border-b-2 group-hover:border-black
                                                             border-b-2 border-primaryGreen
                                                             w-fit flex gap-x-2 items-center font-semibold font-jumper`}
                                                    >
                                                        Request a Quote <FaArrowRight />
                                                    </span>
                                                    <div className="flex items-center gap-x-3">
                                                        <button
                                                            onClick={() => handleMatch(item)}
                                                            className={`
                                                                 group-hover:bg-white group-hover:border-2  border-black
                                                                 transition-all duration-300 ease-linear text-black
                                                                 bg-primaryGreen
                                                                 
                                                                  rounded-full text-black px-5 py-2 pt-3 h-fit w-fit text-xs text-nowrap font-jumper font-semibold`}
                                                        >
                                                            Buy Packages
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-span-2 grid justify-end'>
                                                <img
                                                    src={`${StorageURL}/${item?.category?.logo}`}
                                                    width={100}
                                                    className="flex-shrink-0 w-12 h-12 object-contain"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))
        );
    };

    const tabsHeader = (
        <div className="flex gap-x-7">
            {[{ _id: 'all', name: 'All' }, ...Tabs].map((tab, index) => (
                <button
                    key={index}
                    className={`py-2 pb-3 text-center !text-md cursor-pointer transition-colors 
                ${activeTab === tab?._id
                            ? 'text-white font-semibold border-b-2 border-primaryGreen transition-all duration-700 ease-linear'
                            : 'text-gray-400 border-b-2 border-transparent'
                        }`}
                    onClick={() => handleSelectedTab(tab)}
                >
                    {tab?.name}
                </button>
            ))}
        </div>
    )

    return Tabs && (
        <div className="overflow-hidden text-white bg-transparent">
            <div className="mt-4">
                {filteredMatches.length > 0 && (
                    <CustomSlider
                        header={tabsHeader}
                        slides={getSlides()}
                        slidesToShow={2}
                        slidesToScroll={1}
                        infinite={false}
                        speed={500}
                        responsive={[
                            {
                                breakpoint: 1024,
                                settings: {
                                    slidesToShow: 2,
                                    slidesToScroll: 1,
                                    centerMode: true,
                                    centerPadding: '10px',
                                },
                            },
                            {
                                breakpoint: 768,
                                settings: {
                                    slidesToShow: 1,
                                    slidesToScroll: 1,
                                    centerMode: false,
                                    centerPadding: '0px',
                                },
                            },
                        ]}
                    />
                )}
            </div>
        </div>
    );
};

export default Tabs;
