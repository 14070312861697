import React from 'react'
import { useAppContext } from '../../UseContext/ContextProvider';
import { MdOutlineEdit } from 'react-icons/md';
import { RiDeleteBinLine } from 'react-icons/ri';
import DeleteModal from '../Modal/DeleteModal';
import { useNavigate } from 'react-router-dom';
import { axiosInstance, StorageURL, URL } from '../../utilities/ConstantData';
import { toast } from 'react-toastify';
import { BiCalendarWeek } from 'react-icons/bi';
const MatchCard = ({ data, onClick, width, overlay }) => {
    const { showBackdropWithContent, convertToAmPm, convertToDateFormat, setSelectedEditMatch, closeModal, handleErrors, setMatchesData } = useAppContext()
    const navigate = useNavigate()
    const handleShowBackdrop = () => {
        setSelectedEditMatch(data)
        navigate("/match/edit")
    }

    const filterCategory = (id) => {
        setMatchesData(prevData => prevData.filter(team => team._id !== id));
    };

    const handleDelete = async (id) => {
        const deleteRequest = axiosInstance().delete(`${URL}/event/delete/${id}`);
        toast.promise(
            deleteRequest,
            {
                pending: 'Deleting match...',
                success: 'Match deleted successfully',
                error: (error) => {
                    const errors = error?.response?.data?.errors;
                    const statusCode = error?.response?.status;

                    if (statusCode === 401) {
                        try {
                            localStorage.removeItem('MH_UserData');
                        } catch (e) {
                            console.log(e);
                        } finally {
                            navigate("/Login");
                        }
                        return errors || 'Unauthorized access. Redirecting to login.';
                    } else {
                        handleErrors(error);
                        return errors || 'An error occurred while deleting the match';
                    }
                }
            }
        ).then(() => {
            filterCategory(id);
            closeModal();
        }).catch((error) => {
            console.error(error);
        });
    };

    const handleBackdrop = () => {
        const content = (
            <DeleteModal handleDelete={handleDelete} item="match" id={data._id} />
        )
        showBackdropWithContent(content)
    }
    const { selectedMatch } = useAppContext()
    const imageWidth = width === 'large' ? '60' : width === 'small' ? '50' : ''
    return (
        <div className={` ${width === 'small' ? 'xl:col-span-4' : 'xl:col-span-3'} group md:col-span-6 col-span-12 bg-[#141414] cursor-pointer rounded-xl px-5 py-6 text-white relative  ${data.id == selectedMatch ? 'border-1 border-primaryGreen' : ''}`} onClick={onClick} >
            {overlay && (
                <div className='absolute opacity-0 h-0 group-hover:h-full group-hover:opacity-100 w-full backdrop-blur-xl top-0 left-0 transition-all duration-200 ease-linear rounded-xl flex items-center justify-center z-20'>
                    <div className='flex items-center gap-x-3 rounded-md text-xs w-fit mt-2'>
                        <div className='flex gap-x-3 items-center border-borderInput border px-2 py-1 rounded-md cursor-pointer' onClick={() => handleShowBackdrop()}>
                            Edit <MdOutlineEdit />
                        </div>
                        <div onClick={() => handleBackdrop()} className='flex gap-x-1  items-center text-white bg-red-700 px-2 py-1 rounded-md cursor-pointer font-semibold'>
                            Delete <RiDeleteBinLine className='mt-[1px]' />
                        </div>
                    </div>
                </div>
            )}
            <div className='grid grid-cols-12 justify-center gap-y-2'>
                <div className='col-span-3'>
                    {data?.team1 && <div className='flex flex-col gap-2 items-center'>
                        <div className='h-16 w-16 relative'>
                            <img src={`${StorageURL}/${data?.team1?.logo}`} className='absolute h-full w-full object-contain' alt='logo' width={imageWidth} />
                        </div>
                        <p className='text-white font-semibold text-sm'>{data?.team1?.name}</p>
                    </div>
                    }
                </div>
                <div className='col-span-6 grid items-center'>
                    <div className='flex flex-col items-center gap-2'>
                        <p className={`${width === 'small' ? 'text-[1rem]' : 'text-[1rem]'} whitespace-nowrap text-secondaryText font-semibold`}>{data?.category?.name || ""} </p>
                        <span className='text-2xl font-semibold'>VS</span>
                        <div className='flex items-center gap-1'>
                            <div className='h-3 w-3 rounded-full bg-[#FF824D]'></div>
                            <span className='text-xs pr-2 text-secondaryText'>{convertToAmPm(data.time)}</span>
                        </div>
                        <div className='flex gap-x-1'>
                            <BiCalendarWeek size={15}/>
                            <span className='text-xs text-center text-secondaryText'>{convertToDateFormat(data.date)}</span>
                        </div>
                    </div>
                </div>
                <div className='col-span-3'>
                    {data?.team2 && <div className='flex flex-col gap-2 items-center'>
                        <div className='h-16 w-16 relative'>
                            <img src={`${StorageURL}/${data?.team2?.logo}`} className='absolute h-full w-full object-contain' alt='logo' width={imageWidth} />
                        </div>
                        <p className='text-white font-semibold text-xs'>{data?.team2?.name}</p>
                    </div>}
                </div>
            </div>
        </div>
    )
}

export default MatchCard
