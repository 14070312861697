import React, { useRef, useEffect } from 'react';
import { useAppContext } from '../../UseContext/ContextProvider';
import { useLocation } from 'react-router-dom';

const Backdrop = () => {
  const { isModalOpen, closeModal, backdropContent } = useAppContext();
  const location = useLocation();
  useEffect(() => {
    if (isModalOpen) {
      closeModal();
    }
  }, [location]);
  if (!isModalOpen) return null;

  return (
    <div className={`fixed inset-0 flex justify-center w-full h-[100vh] items-center`}>
      <div
        aria-hidden="true"
        className={`inset-0 w-full h-full bg-black/50 cursor-pointer absolute backdrop-blur-lg`}
        onClick={closeModal}
      ></div>
      <div
        className={`relative overflow-x-hidden custom-scroll rounded-md`}
      >
        {backdropContent}
      </div>
    </div>
  );
};

export default Backdrop;
