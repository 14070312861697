import React, { useEffect, useState } from 'react';
import { PrimaryButton } from '../../Components/UiElements/Buttons';
import { useAppContext } from '../../UseContext/ContextProvider';
import { useNavigate } from 'react-router-dom';
import {
  axiosInstance,
  StorageURL,
  URL,
} from '../../utilities/ConstantData';
import Loader from '../../Components/UiElements/Loader';
import { toast } from 'react-toastify';
const Events = () => {
  const {
    handleErrors,
    convertToAmPm,
    convertToDateFormat,
    setselectedUserMatch,
    selectedEvent,
  } = useAppContext();
  const [loading, setLoading] = useState(true);
  const [matchesData, setMatchesData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedEvent) {
      fetchMatches();
    } else {
      navigate(-1);
    }
  }, [selectedEvent]);

  const fetchMatches = async () => {
    axiosInstance()
      .get(`${URL}/event/all`)
      .then((res) => {
        const data = res.data.data;
        const filteredData = data.filter(
          (item) =>
            item?.category?.status != 'inactive' &&
            item?.category?.status &&
            item?.team1?.status != 'inactive' &&
            item?.team1?.status &&
            item?.team2?.status != 'inactive' &&
            item?.team2?.status &&
            item?.category?._id == selectedEvent._id
        );
        calculateUpcomingMatch(filteredData);
      })
      .catch((error) => {
        setLoading(false);
        const errors = error?.response?.data?.errors;
        const statusCode = error?.response?.status;
        if (statusCode == 401) {
          toast.error(errors);
          try {
            localStorage.removeItem('MH_UserData');
          } catch (error) {
            console.log(error);
          } finally {
            navigate('/Login');
          }
        } else {
          handleErrors(error);
        }
      });
  };

  const calculateUpcomingMatch = (filteredData) => {
    const upcoming = filteredData.filter((item) => {
      const matchDate = new Date(item.date);
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return matchDate >= today;
    });
    setMatchesData(upcoming);
    setLoading(false);
  };

  const handleMatch = (data) => {
    navigate('/select-package');
    setselectedUserMatch(data);
  };
  return loading ? (
    <Loader />
  ) : (
    <div className="grid grid-cols-12 lg:gap-x-8 gap-y-8">
      <div className="col-span-12 font-manrope text-white text-2xl font-semibold">
        All upcoming matches of {selectedEvent.name}
      </div>
      <div className="col-span-12">
        {matchesData.length == 0 && (
          <h2 className="text-center text-white">
            There is no upcoming match for {selectedEvent.name}.
          </h2>
        )}
        <div className="grid grid-cols-12 gap-5">
          {matchesData.length > 0 &&
            matchesData.map((data, index) => {
              return (
                <div
                  onClick={() => handleMatch(data)}
                  key={index}
                  className="col-span-4"
                >
                  <div className="border border-borderInput rounded-lg p-5 grid grid-cols-12 text-white items-center gap-y-5 hover:border-primaryGreen  hover:cursor-pointer transition-all duration-300 ease-linear">
                    <div className="col-span-12 text-sm">
                      <div className="grid grid-cols-12 items-center">
                        <div className="col-span-3">
                          <div className="flex flex-col gap-y-2 items-center justify-center">
                            <div className="h-16 w-16 relative">
                              <img
                                src={`${StorageURL}/${data?.team1?.logo}`}
                                className="absolute h-full w-full"
                              />
                            </div>
                            <div>{data?.team1?.name}</div>
                          </div>
                        </div>
                        <div className="col-span-6">
                          <div className="flex flex-col gap-y-2 items-center text-center">
                            <div className='text-[1rem] text-[#aaaaaa]'>{data?.category?.name || ''}</div>
                            <div className='text-2xl font-semibold'>VS</div>
                            <div className="flex gap-x-1 items-center">
                              <div className="h-2 w-2 bg-red-500 rounded-full"></div>
                              <div className='text-[#aaaaaa]'>{convertToAmPm(data.time)}</div>
                            </div>
                            <div className="text-center text-[#aaaaaa]">
                              {convertToDateFormat(data.date)}
                            </div>
                          </div>
                        </div>
                        <div className="col-span-3">
                          <div className="flex flex-col gap-y-2 items-center">
                            <div className="h-16 w-16 relative">
                              <img
                                src={`${StorageURL}/${data?.team2?.logo}`}
                                className="absolute h-full w-full"
                              />
                            </div>
                            <div>{data?.team2?.name}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          {/* <div className='col-span-4 p-5'>
                        <CustomSlider
                            slides={slides}
                            slidesToShow={1}
                            slidesToScroll={1}
                            infinite={false}
                            speed={500}
                            header='Trending Now'
                            responsive={[
                                {
                                    breakpoint: 1024,
                                    settings: {
                                        slidesToShow: 2,
                                        slidesToScroll: 1,
                                        centerMode: true,
                                        centerPadding: '10px',
                                    },
                                },
                                {
                                    breakpoint: 768,
                                    settings: {
                                        slidesToShow: 1,
                                        slidesToScroll: 1,
                                        centerMode: false,
                                        centerPadding: '0px',
                                    },
                                },
                            ]}
                        />
                    </div> */}
          {/* <div className='col-span-8 bg-[#161616] p-5 rounded-xl'>
                        <div className='grid grid-cols-12'>
                            <div className='col-span-12'>
                                <CustomSlider
                                    slides={slides}
                                    slidesToShow={2}
                                    slidesToScroll={1}
                                    infinite={false}
                                    speed={500}
                                    header='Upcoming Matches'
                                    responsive={[
                                        {
                                            breakpoint: 1024,
                                            settings: {
                                                slidesToShow: 2,
                                                slidesToScroll: 1,
                                                centerMode: true,
                                                centerPadding: '10px',
                                            },
                                        },
                                        {
                                            breakpoint: 768,
                                            settings: {
                                                slidesToShow: 1,
                                                slidesToScroll: 1,
                                                centerMode: false,
                                                centerPadding: '0px',
                                            },
                                        },
                                    ]}
                                />
                            </div>
                        </div>
                    </div> */}
        </div>
      </div>
    </div>
  );
};

export default Events;
