import React, { useEffect, useState } from 'react';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../Components/UiElements/Buttons';
import { TbClock } from 'react-icons/tb';
import { IoLocationOutline } from 'react-icons/io5';
import CustomTabs from '../../Components/Tabs/CustomTabs';
import { useAppContext } from '../../UseContext/ContextProvider';
import Loader from '../../Components/UiElements/Loader';
import { useNavigate } from 'react-router-dom';
import { axiosInstance2, URL } from '../../utilities/ConstantData';
import { toast } from 'react-toastify';

const UserPayment = () => {
  const navigate = useNavigate();
  const [bookings, setBookings] = useState();
  const {
    handleErrors,
    convertToAmPm,
    extractDate,
    getDayOfWeek,
    extractMonth,
    setSelectedOrderDetail,
  } = useAppContext();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const Uid = JSON.parse(localStorage.getItem('MH_UserData')).id;
    try {
      const res = await axiosInstance2().get(
        `${URL}/booking/user/${Uid}`
      );
      const bookings = res.data.data.bookings;
      setBookings(bookings);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleApiError(error);
    }
  };

  const handleApiError = (error) => {
    const errors = error?.response?.data?.errors;
    const statusCode = error?.response?.status;
    if (statusCode === 401) {
      toast.error(errors);
      localStorage.removeItem('MH_UserData');
      navigate('/Login');
    } else {
      handleErrors(error);
    }
  };

  const paymentData = [
    {
      label: 'Active Payments',
      content: (
        <div className="grid grid-cols-1 mt-7 gap-y-5">
          {Array(5)
            .fill(0)
            .map((item) => {
              return (
                <>
                  <div className="bg-white rounded-lg grid grid-cols-4 min-h-72 py-5 px-10">
                    <div className="col-span-3 grid items-center">
                      <div className="grid grid-cols-12 gap-x-16">
                        <div className="col-span-6 grid grid-cols-1 gap-y-4 gap-x-5">
                          <div className="flex gap-x-5 font-semibold items-center">
                            <p className="text-sm">Package</p>
                            <button className="rounded-full py-2 px-3 flex gap-x-2 bg-[#88F67E]/50 items-center justify-center text-sm">
                              <img src="assets/images/svgs/payment/crown.svg" />
                              <span>Platinum</span>
                            </button>
                          </div>
                          <div className="flex flex-col gap-y-2 font-jumper">
                            <span className="text-3xl font-extrabold flex gap-x-3">
                              <span>Chelsea</span>
                              <span>VS</span>
                              <span>Manchester</span>
                            </span>
                            <span className="text-sm font-semibold font-manrope">
                              Lorem Ipsum is simply dummy text of the{' '}
                              <br /> printing and typesetting
                              industry.
                            </span>
                          </div>
                          <div className="grid grid-cols-2 items-center font-semibold font-manrope mt-3">
                            <div className="flex gap-x-2 items-center">
                              <TbClock size={20} />
                              <span className="text-sm">5:00pm</span>
                            </div>
                            <div className="flex gap-x-2">
                              <IoLocationOutline size={20} />
                              <span className="text-sm">
                                Stadium, State
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-span-3 flex flex-col justify-center h-full gap-y-4">
                          <p className="text-sm font-semibold">
                            Amount Paid
                          </p>
                          <p className="text-4xl font-bold">
                            $25000.00
                          </p>
                          <button className="font-semibold rounded-full py-2 px-5 flex bg-[#E3F0FF]/50 text-[#045FC6] items-center justify-center text-sm">
                            Download Invoice
                          </button>
                        </div>
                        <div className="col-span-3 flex flex-col mt-5 h-full gap-y-4">
                          <p className="text-sm font-semibold">
                            Total Amount Due
                          </p>
                          <p className="text-4xl font-bold">
                            $500.00
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-1 bg-black rounded-lg p-10">
                      <div className="grid grid-cols-1 items-center gap-y-4">
                        <p className="text-white">Next Installment</p>
                        <p className="text-4xl text-white font-bold">
                          $50.00
                        </p>
                        <div className="flex flex-col gap-y-3">
                          <button className="font-semibold rounded-full py-2 px-5 flex bg-[#FFD8D8] text-[#E10000] items-center justify-center text-sm">
                            Due by 3rd October
                          </button>
                          <PrimaryButton
                            className="border border-white"
                            size="medium"
                          >
                            Pay Now
                          </PrimaryButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
        </div>
      ),
    },
    {
      label: 'Past Payments',
      content: (
        <div className="grid grid-cols-1 mt-7 gap-y-5">
          {bookings?.length > 0 ? (
            bookings.map((item) => {
              return (
                <>
                  <div className="bg-white rounded-lg grid grid-cols-4 min-h-72 p-5 px-10">
                    <div className="col-span-4 grid items-center">
                      <div className="grid grid-cols-12 gap-x-16">
                        <div className="col-span-8 grid grid-cols-1 gap-y-4 gap-x-5">
                          <div className="flex gap-x-5 font-semibold items-center">
                            <p className="text-sm">Package</p>
                            <button className="rounded-full py-2 px-3 flex gap-x-2 bg-[#88F67E]/50 items-center justify-center text-sm">
                              <img src="assets/images/svgs/payment/crown.svg" />
                              <span>{item?.package?.name}</span>
                            </button>
                          </div>
                          <div className="flex flex-col gap-y-2 font-manrope">
                            <span className="text-3xl font-extrabold flex gap-x-3">
                              <span>{item?.match?.team1?.name}</span>
                              <span>VS</span>
                              <span>{item?.match?.team2?.name}</span>
                            </span>
                            <span className="text-sm font-semibold font-manrope">
                            {item?.package?.description}
                            </span>
                          </div>
                          <div className="grid grid-cols-2 items-center font-semibold font-manrope mt-3">
                            <div className="flex gap-x-2 items-center">
                              <TbClock size={20} />
                              <span className="text-sm">{convertToAmPm(item?.match?.time)}</span>
                            </div>
                            <div className="flex gap-x-2">
                              <IoLocationOutline size={20} />
                              <span className="text-sm">
                              {item?.match?.venue}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-span-4 flex flex-col justify-center items-end h-full gap-y-4">
                          <p className="text-sm font-semibold">
                            Amount Paid
                          </p>
                          <p className="text-4xl font-bold">
                           {`$${item?.package?.price}`}
                          </p>
                          <div>
                            <button className="font-semibold rounded-full py-2 px-5 flex bg-[#E3F0FF]/50 text-[#045FC6] items-center justify-center text-sm">
                              Download Invoice
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })
          ) : (
            <div className="">
              <h5 className="text-center font-bold mt-10 text-[#88F67E]">
                No upcoming tickets found!
              </h5>
            </div>
          )}
        </div>
      ),
    },
    // {
    //     label: 'Billing Information'
    // }
  ];
  return loading ? (
    <Loader />
  ) : (
    <div className="grid grid-cols-1 gap-y-10">
      <div>
        <SecondaryButton>My Payments</SecondaryButton>
      </div>
      <div className="headerText">My Payments</div>
      <div>
        <CustomTabs tabs={paymentData} />
      </div>
    </div>
  );
};

export default UserPayment;
