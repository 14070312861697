import React, { useState, useEffect } from 'react';
import { PrimaryButton } from '../../Components/UiElements/Buttons';
import { RiDeleteBinLine } from 'react-icons/ri';
import { MdOutlineEdit } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import DeleteModal from '../../Components/Modal/DeleteModal';
import { useAppContext } from '../../UseContext/ContextProvider';
import { axiosInstance, URL } from '../../utilities/ConstantData';
import Loader from '../../Components/UiElements/Loader';
import { toast } from 'react-toastify';
const AllPackages = () => {
  const navigate = useNavigate();
  const {
    showBackdropWithContent,
    handleErrors,
    setSelectedEditPackage,
    closeModal,
  } = useAppContext();
  const [loading, setLoading] = useState(true);
  const [PackagesData, setPackagesData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    axiosInstance()
      .get(`${URL}/package/all`)
      .then((res) => {
        setLoading(false);
        const data = res.data.data;
        setPackagesData(data);
      })
      .catch((error) => {
        setLoading(false);
        const errors = error?.response?.data?.errors;
        const statusCode = error?.response?.status;
        if (statusCode == 401) {
          toast.error(errors);
          navigate('/Login');
        } else {
          handleErrors(error);
        }
      });
  };

  const CreatePackage = () => {
    navigate('/package/create');
  };
  const filterPacakge = (id) => {
    setPackagesData((prevData) =>
      prevData.filter((team) => team._id !== id)
    );
  };

  const handleDelete = async (id) => {
    const deleteRequest = axiosInstance().delete(
      `${URL}/package/delete/${id}`
    );
    toast
      .promise(deleteRequest, {
        pending: 'Deleting package...',
        success: 'Package deleted successfully',
        error: (error) => {
          const errors = error?.response?.data?.errors;
          const statusCode = error?.response?.status;

          if (statusCode === 401) {
            try {
              localStorage.removeItem('MH_UserData');
            } catch (e) {
              console.log(e);
            } finally {
              navigate('/Login');
            }
            return (
              errors || 'Unauthorized access. Redirecting to login.'
            );
          } else {
            handleErrors(error);
            return (
              errors || 'An error occurred while deleting the package'
            );
          }
        },
      })
      .then(() => {
        filterPacakge(id);
        closeModal();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleEdit = (item) => {
    setSelectedEditPackage(item);
    navigate('/package/edit');
  };

  const handleShowBackdrop = (id) => {
    const content = (
      <DeleteModal
        item="package"
        handleDelete={handleDelete}
        id={id}
      />
    );
    showBackdropWithContent(content);
  };

  const handleToggleStatus = async (id, status) => {
    const new_status = status === 'active' ? 'deactivated' : 'activated';
    const updateStatusRequest = axiosInstance().put(`${URL}/package/edit/${id}`, { status: new_status === 'activated' ? 'active' : 'deactive' });

    toast.promise(
      updateStatusRequest,
      {
        pending: `Updating package status...`,
        success: `Package ${new_status} successfully!`,
        error: (error) => {
          const errors = error?.response?.data?.errors;
          const statusCode = error?.response?.status;

          if (statusCode === 401) {
            try {
              localStorage.removeItem('MH_UserData');
            } catch (e) {
              console.log(e);
            } finally {
              navigate("/Login");
            }
            return errors || 'Unauthorized access. Redirecting to login.';
          } else {
            handleErrors(error);
            return errors || `An error occurred while updating the package status to ${new_status}`;
          }
        }
      }
    ).then(() => {
      setPackagesData((prevData) => {
        return prevData.map((item) =>
          item._id === id ? { ...item, status: item.status === 'active' ? 'deactive' : 'active' } : item
        );
      });
    }).catch((error) => {
      console.error(error);
    });
  };

  return loading ? (
    <Loader />
  ) : (
    <div className="grid grid-cols-12 text-white gap-10">
      <div className="col-span-12">
        <div className="grid grid-cols-12 gap-8">
          <div className="col-span-12">
            <div className="grid grid-cols-12">
              <div className="col-span-8 headerText">
                All Packages
              </div>
              <div className="grid col-span-4 justify-end">
                <PrimaryButton
                  size="medium"
                  className="font-semibold"
                  onClick={() => CreatePackage()}
                >
                  Create New Package
                </PrimaryButton>
              </div>
            </div>
          </div>
          <div className="col-span-12">
            <div className="grid grid-cols-12 lg:gap-x-8 gap-y-8">
              <div className="col-span-12">
                <div className="grid grid-cols-12 lg:gap-x-5 gap-y-5">
                  <div className="grid lg:col-span-12 col-span-12">
                    <div className="grid grid-cols-12 gap-5">
                      {PackagesData.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className={`${item.status != 'active' ? ' mix-blend-luminosity' : ''} grid lg:col-span-4 relative col-span-12 bg-gradient-to-t shadow-lg from-primaryGreen/5 to-transparent bg-[#161616] rounded-2xl p-8 min-h-64`}
                          >
                            <div className="absolute top-5 right-5 ">
                              <div className="flex gap-x-2 text-xs">
                                <label className="inline-flex items-center cursor-pointer">
                                  <input
                                    checked={item.status == 'active' ? true : false}
                                    type="checkbox"
                                    className="sr-only peer"
                                    onChange={() => handleToggleStatus(item._id, item.status)}
                                  />
                                  <div className="relative w-8 h-4 py-2 bg-white/90 peer-focus:outline-none rounded-full after:bg-red-600 peer-checked:after:translate-x-3 peer-checked:after:bg-primaryGreen after:content-[''] after:absolute after:top-[1.99px] after:start-[4px] after:rounded-full after:h-3 after:w-3 after:transition-all"></div>
                                  <span className="ms-2 text-xs text-white/80 font-bold">
                                    {item.status == 'active' ? "Active" : "Deactivated"}
                                  </span>
                                </label>
                              </div>
                            </div>
                            <div className="flex flex-col gap-5">
                              <h1 className="text-3xl font-bold">
                                {item.name}
                              </h1>
                              <p
                                className="text-white/50 text-sm truncate-multiline"
                                style={{ '--line-clamp': 4 }}
                              >
                                {item.description}
                              </p>
                              <div className="flex justify-between items-center">
                                <h2 className="text-white font-bold text-3xl">
                                  ${item.price}
                                </h2>
                                <div className="flex justify-end gap-x-3">
                                  <div
                                    onClick={() => handleEdit(item)}
                                    className="bg-primaryGreen h-8 w-8 rounded-md flex justify-center items-center cursor-pointer"
                                  >
                                    <MdOutlineEdit className="text-black" />
                                  </div>
                                  <div
                                    onClick={() =>
                                      handleShowBackdrop(item._id)
                                    }
                                    className="bg-red-800 h-8 w-8 rounded-md flex justify-center items-center cursor-pointer"
                                  >
                                    <RiDeleteBinLine className="text-white" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllPackages;
