import React from 'react';
import { Navigate } from 'react-router-dom';

const getMH_UserData = () => JSON.parse(localStorage.getItem('MH_UserData'));

const ProtectedRoute = ({ type, children }) => {
  const user = getMH_UserData();

  if (type === "public") {
    if (user?.token) {
      return <Navigate to={user?.userType === 'ap%4k45a5sd' ? "/admin/dashboard" : "/dashboard"} replace />;
    }
    return children;
  }

  if (!user?.token) {
    return <Navigate to="/login" replace />;
  }
  
  if (!user?.token) {
    return <Navigate to="/login" replace />;
  }

  if (type === "admin" && user?.userType !== 'ap%4k45a5sd') {
    return <Navigate to="/dashboard" replace />;
  }

  if (type === "user" && user?.userType !== 'a2d%lsakd4A') {
    return <Navigate to="/admin/dashboard" replace />;
  }

  return children;
};

export default ProtectedRoute;
