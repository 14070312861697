import React, { createContext, useState, useContext } from 'react';
import { toast } from 'react-toastify';
const AppContext = createContext();

export const ContextProvider = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [backdropContent, setBackdropContent] = useState(null);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const [searchTxt, setSearchTxt] = useState("")
  const [userType, setUserType] = useState("");
  const closeModal = () => setIsModalOpen(false);
  const [isOpen, setIsOpen,] = useState(false);
  // events
  const [selectedEvent, setSelectedEvent] = useState('')
  const [selectedOrderDetail, setSelectedOrderDetail] = useState()
  const showBackdropWithContent = (content) => {
    setBackdropContent(content);
    openModal();
  };
  const [categoryData, setCategoryData] = useState([])
  const [PackageData, setPackageData] = useState([])
  const [selectedUserMatch, setselectedUserMatch] = useState()
  const [selectedUserPackage, setSelectedUserPackage] = useState()
  const [MatchesData, setMatchesData] = useState([])
  const [profileUpdation, setProfileUpdation] = useState()
  const [signUpDetails, setSignUpDetails] = useState({
    userName: '',
    email: "",
    password: "",
    phone: "",
  })
  const [selectedEditCategory, setSelectedEditCategory] = useState({})
  const [selectedEditMatch, setSelectedEditMatch] = useState({})
  const [selectedEditPackage, setSelectedEditPackage] = useState({})
  const [forgetPassworddata, setforgetPassworddata] = useState({
    email: "",
    otp: "",
    new_password: ""
  })
  const [selectedMatch, setSelectedMatch] = useState(null);
  const signUpDetailsSetter = (e) => {
    const { name, value } = e.target
    setSignUpDetails((pre) => ({ ...pre, [name]: value }))
  }

  const forgetPasswordsSetter = (e) => {
    const { name, value } = e.target
    setforgetPassworddata((pre) => ({ ...pre, [name]: value }))
  }

  const isEmailValidate = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const result = emailRegex.test(email);
    if (!email) {
      toast.error("Email is required")
    }
    else if (!result) {
      toast.error("Invalid email format.")
    }
    return result
  };

  const handleErrors = (error) => {
    const errors = error?.response?.data?.errors
    if (typeof errors == 'string') {
      toast.error(errors);
    } else if (errors && Array.isArray(errors)) {
      errors.forEach((err) => {
        toast.error(err.msg);
      });
    } else {
      toast.error('An unknown error occurred.');
    }
  }

  function convertToAmPm(timestamp) {
    const date = new Date(timestamp);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const amPm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
    return `${hours}:${formattedMinutes} ${amPm}`;
  }

  function convertToDateFormat(isoString) {
    const date = new Date(isoString);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  }

  function extractDate(dateString) {
    const dateObj = new Date(dateString);
    const date = dateObj.getDate().toString().padStart(2, '0'); 
    return date;
}

  function extractDate(dateString) {
    const dateObj = new Date(dateString);
    const date = dateObj.getDate().toString().padStart(2, '0'); 
    return date;
}

  function extractMonth(dateString) {
    const dateObj = new Date(dateString);
    const month = dateObj.toLocaleString('en-US', { month: 'short' });
    return month;
}

function getDayOfWeek(dateString) {
    const dateObj = new Date(dateString);
    return dateObj.toLocaleString('en-US', { weekday: 'long' }); // Returns full weekday name
}




  return (
    <AppContext.Provider
      value={{
        isModalOpen,
        openModal,
        closeModal,
        signUpDetails,
        setSignUpDetails,
        signUpDetailsSetter,
        isEmailValidate,
        forgetPassworddata,
        setforgetPassworddata,
        forgetPasswordsSetter,
        showBackdropWithContent,
        backdropContent,
        isOpen,
        setIsOpen,
        setSelectedMatch,
        selectedMatch,
        handleErrors,
        setSelectedEditCategory,
        selectedEditCategory,
        categoryData,
        setCategoryData,
        PackageData,
        setPackageData,
        convertToAmPm,
        convertToDateFormat,
        selectedEditMatch,
        setSelectedEditMatch,
        MatchesData,
        setMatchesData,
        selectedEditPackage,
        setSelectedEditPackage,
        profileUpdation,
        setProfileUpdation,
        setSelectedEvent,
        selectedEvent,
        selectedUserMatch,
        setselectedUserMatch,
        selectedUserPackage,
        setSelectedUserPackage,
        searchTxt, setSearchTxt,
        userType, setUserType,
        isCollapsed, setIsCollapsed,
        extractDate,
        getDayOfWeek,
        extractMonth,
        selectedOrderDetail, setSelectedOrderDetail
      }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);