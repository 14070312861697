import React, { useEffect } from 'react';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../Components/UiElements/Buttons';
import CustomTabs from '../../Components/Tabs/CustomTabs';
import card from '../../../src/assets/images/tickets/ticketCard.png';
import barcode from '../../../src/assets/images/tickets/horizontalBar.png';
import { useAppContext } from '../../UseContext/ContextProvider';
import { StorageURL } from '../../utilities/ConstantData';
import { useNavigate } from 'react-router-dom';
const EventDetail = () => {
  const {
    convertToAmPm,
    extractDate,
    getDayOfWeek,
    extractMonth,
    selectedOrderDetail,
  } = useAppContext();
const navigate = useNavigate()
  useEffect(()=>{
    if(!selectedOrderDetail){
        navigate(-1)
    }
  },[])

  console.log(selectedOrderDetail,"selec")
  const tabs = [
    {
      label: 'Tickets',
      content: (
        <div className="grid grid-cols-1 mt-[2rem]">
          <div className="flex flex-wrap gap-x-16 gap-y-16">
            {Array(3)
              .fill(0)
              .map((item) => {
                return (
                  <>
                    <div
                      className="w-[24rem] h-[22rem] flex flex-col gap-y-5 px-5 py-5"
                      style={{ backgroundImage: `url(${card})` }}
                    >
                      <div className="font-semibold border-b border-black pb-5">
                        General Admission <br /> Standing - <br />
                        Presale TICKET 1 <br />
                        General Admission <br /> (Ticket Type)
                      </div>
                      <img src={barcode} alt="bardcode" />
                      <div className="mx-5 self-center">
                        <PrimaryButton
                          size="medium"
                          className="border"
                        >
                          Download Ticket Receipt{' '}
                        </PrimaryButton>
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
        </div>
      ),
    },
    {
      label: 'Event Info',
      content: (
        <div className="flex flex-col gap-y-10 text-white">
          <p className="font-semibold text-lg">
            General Admission Only
          </p>
          <p className="text-base leading-8 w-8/12">
            Founded by Tale Of Us, Afterlife has garnered global
            acclaim for its innovative approach to electronic music
            events. Combining mesmerizing visuals, cutting-edge sound
            production, and impeccable lighting design, Afterlife
            creates an immersive atmosphere where reality blends with
            imagination. Each event is a unique journey, captivating
            the senses and transporting attendees to a different
            dimension.
          </p>
        </div>
      ),
    },
    {
      label: 'Venue Info',
      content: (
        <div className="flex flex-col gap-y-10 text-white">
          <p className="text-base leading-8 w-8/12">
            {selectedOrderDetail?.match?.venue}
          </p>
        </div>
      ),
    },
  ];
  return (
    <div className="grid grid-cols-12 gap-y-10">
      <div className="col-span-12">
        <SecondaryButton />
      </div>
      <div className="col-span-12 headerText">My Tickets</div>
      <div className="col-span-12 rounded-lg">
        <div className="grid grid-cols-1">
          <div
            className="h-[20rem] bg-center bg-cover rounded-lg grid items-center px-24"
            style={{
              backgroundImage: `url(${StorageURL}/${selectedOrderDetail?.match?.category?.banner_image})`,
            }}
          >
            <div className="grid grid-cols-2 text-white">
              <div className="grid grid-cols-1 font-semibold text-sm gap-y-3">
                <div className="flex items-center gap-x-2">
                  {`${extractDate(
                    selectedOrderDetail?.match?.date
                  )} ${extractMonth(
                    selectedOrderDetail?.match?.date
                  )}`}
                  <span className="h-5 w-[0.5px] bg-white"></span>
                  {getDayOfWeek(
                    selectedOrderDetail?.match?.date
                  )}{' '}
                  <span className="h-5 w-[0.5px] bg-white"></span>{' '}
                  {convertToAmPm(selectedOrderDetail?.match?.time)}
                </div>
                <div className="text-2xl font-bold">{`${selectedOrderDetail?.match?.team1?.name} VS ${selectedOrderDetail?.match?.team2?.name}`}</div>
                <p className="font-normal font-manrope">
                  {selectedOrderDetail?.match?.category?.description} 
                </p>
              </div>
              <div className="flex gap-x-10 items-center justify-end">
                <div className="flex flex-col gap-y-4 text-xs">
                  <div className="h-20 w-20 rounded-full relative">
                    <img
                      className="absolute h-full w-full object-contain"
                      src={`${StorageURL}/${selectedOrderDetail?.match?.team1?.logo}`}
                    />
                  </div>
                  <p className="text-center">
                    {selectedOrderDetail?.match?.team1?.name}
                  </p>
                </div>
                <div className="flex flex-col gap-y-4 text-xs">
                  <div className="h-20 w-20 rounded-full relative">
                    <img
                      className="absolute h-full w-full object-contain"
                      src={`${StorageURL}/${selectedOrderDetail?.match?.team2?.logo}`}
                    />
                  </div>
                  <p className="text-center">
                    {selectedOrderDetail?.match?.team2?.name}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-span-12">
        <CustomTabs tabs={tabs} />
      </div>
    </div>
  );
};

export default EventDetail;
