import React from 'react'
import { PrimaryButton, SecondaryButton } from '../../Components/UiElements/Buttons'
import bgImage from '../../../src/assets/images/payment/wave.jpg'
import { BiCalendar } from 'react-icons/bi'
const Payment = () => {
    return (
        <div className='grid grid-cols-1 gap-y-10'>
            <div>
                <SecondaryButton />
            </div>
            <div className='headerText'>
                Total Balance
            </div>
            <div className='bg-primaryGreen rounded-lg grid grid-cols-12 lg:px-12 px-5 py-7 items-center lg:gap-0 gap-y-5'>
                <div className='lg:col-span-7 col-span-12'>
                    <div className='grid grid-cols-1 gap-5'>
                        <h1>Total Balance</h1>
                        <div className='flex flex-col gap-y-2'>
                            <span className='md:text-5xl text-3xl font-extrabold'>$3200.00</span>
                            <span className='text-sm'>12 Months Installments Plan</span>
                        </div>
                    </div>
                </div>
                <div className='lg:col-span-5 col-span-12'>
                    <div className='grid grid-cols-3 gap-x-5'>
                        <div className='lg:col-span-2 col-span-3 min-h-52 rounded-lg bg-[#8064d2] bg-center bg-cover overflow-hidden'>
                            <div className='grid grid-cols-1 gap-y-5 h-full p-5 text-white'>
                                <div className='font-bold'>Visa</div>
                                <div className='grid grid-cols-3 items-center'>
                                    <div className='col-span-2'>**** **** ****</div>
                                    <div>8043</div>
                                </div>
                                <div className='grid grid-cols-1'>
                                    <div className='grid grid-cols-2 items-end'>
                                        <div className='flex flex-col gap-y-2'>
                                            <span className='text-[10px] uppercase'>card Holder</span>
                                            <span className='text-base'>Name</span>
                                        </div>
                                        <div className='flex flex-col gap-y-2   '>
                                            <span className='text-[10px]'>EXPIRES</span>
                                            <span className='text-base'>08/25</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='rounded-lg bg-black'>
                            <div className='flex flex-col text-white items-center justify-center h-full'>
                                <span className='text-3xl font-bold'>+</span>
                                <span className='text-bold text-sm'>Add New Card</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex flex-col gap-y-5'>
                {Array(3).fill(0).map((item) => {
                    return (
                        <>
                            <div className='grid grid-cols-1'>
                                <div className='grid grid-cols-4 bg-white rounded-md px-10 py-4 items-center'>
                                    <div className='flex flex-col gap-y-1'>
                                        <div className='text-[12px]'>Next Installment</div>
                                        <div className='text-3xl font-extrabold'>$320.00</div>
                                    </div>
                                    <div className='flex gap-x-3 items-center justify-center'>
                                        <BiCalendar color='black' size={21} />
                                        <span className='font-bold'>20th August</span>
                                    </div>
                                    <div className='flex gap-x-3 items-center justify-center'>
                                        <span className='h-4 w-4 rounded-full bg-primaryGreen'></span>
                                        <span className='font-bold'>Due in 3 days</span>
                                    </div>
                                    <div className='flex justify-end'>
                                        <PrimaryButton color='green' size='medium'>Pay Now</PrimaryButton>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                })}
            </div>
        </div>
    )
}

export default Payment
