import React, { useState, useEffect, useRef, useMemo } from 'react';
// import { BiEdit } from 'react-icons/bi';
import { CgProfile } from 'react-icons/cg';
import { MdOutlineMailOutline } from 'react-icons/md';
import { LabelInput } from '../../Components/UiElements/TextInputs';
import { PrimaryButton } from '../../Components/UiElements/Buttons';
import { FaRegStar } from 'react-icons/fa6';
import placeholder from '../../../src/assets/images/userdashboard/placeholder.png';
import CustomTabs from '../../Components/Tabs/CustomTabs';
import { Table } from '../../Components/ReactTable/Table';
import { VscActivateBreakpoints } from 'react-icons/vsc';
import { useNavigate, useParams } from 'react-router-dom';
import {
  axiosInstance,
  StorageURL,
  URL,
} from '../../utilities/ConstantData';
import Loader from '../../Components/UiElements/Loader';
import { useAppContext } from '../../UseContext/ContextProvider';
import { toast } from 'react-toastify';

const ViewDetails = () => {
  let { id } = useParams();
  const [orderData, setOrderData] = useState([]);
  const [MH_UserData, setMH_UserData] = useState();
  const { handleErrors, convertToDateFormat } = useAppContext();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(false);
  const [status, setStatus] = useState();
  useEffect(() => {
    fetchBookings(id);
  }, [id]);

  const fetchBookings = (id) => {
    axiosInstance()
      .get(`${URL}/booking/user/${id}`)
      .then((res) => {
        setLoading(false);
        const data = res.data.data;
        setMH_UserData(data.user);
        setStatus(data?.user?.status == 'active' ? true : false);
        setOrderData(data.bookings);
      })
      .catch((error) => {
        setLoading(false);
        const errors = error?.response?.data?.errors;
        const statusCode = error?.response?.status;
        if (statusCode == 401) {
          toast.error(errors);
          try {
            localStorage.removeItem('MH_UserData');
          } catch (error) {
            console.log(error);
          } finally {
            navigate('/Login');
          }
        } else {
          handleErrors(error);
        }
      });
  };

  const cols = useMemo(
    () => [
      {
        header: 'ID',
        cell: (row) => `#MH${row.row.original.bookingNo}` || 'N/A',
      },
      {
        header: 'Order Name',
        cell: (row) => (
          <div>
            <p>{row.row.original?.package?.name}</p>
            <p>{`(${row.row.original?.match?.team1?.name} Vs ${row.row.original?.match?.team2?.name} )`}</p>
          </div>
        ),
      },
      {
        header: 'Payment Method',
        cell: 'Milestone 3',
        accessorKey: 'Milestone 3',
      },
      {
        header: 'Payment Type',
        cell: 'Milestone 3',
        accessorKey: 'Milestone 3',
      },
      {
        header: 'Amount',
        cell: (row) => `$${row.row.original?.package?.price}`,
      },
      {
        header: 'Date',
        cell: (row) =>
          `${convertToDateFormat(row.row.original?.createdAt)}`,
      },
    ],
    []
  );

  console.log(MH_UserData, 'MH_UserData');

  const data = [{ name: 'name' }];

  const tabs = [
    {
      label: 'Order History',
      content: (
        <div className="grid grid-cols-12">
          <div className="col-span-12">
            {orderData.length > 0 ? (
              <Table data={orderData} columns={cols} showNavigation />
            ) : (
              <div className="text-center mt-3 flex h-full justify-center items-center">
                <h2>There are currently no orders for this user</h2>
              </div>
            )}
          </div>
        </div>
      ),
    },
    // {
    //   label: 'Edit User Details',
    //   content: (
    //     <div className="grid grid-cols-12 gap-5">
    //       <div className="col-span-6">
    //         <LabelInput label="Name" />
    //       </div>
    //       <div className="col-span-6">
    //         <LabelInput label="Email" />
    //       </div>
    //       <div className="col-span-12">
    //         <LabelInput
    //           type="password"
    //           label="Password"
    //           showEyeIcon={true}
    //         />
    //       </div>
    //       <div className="col-span-12 grid justify-end">
    //         <PrimaryButton size="medium">Update</PrimaryButton>
    //       </div>
    //     </div>
    //   ),
    // },
  ];

  const handleToggleStatus = async () => {
    setLoading2(true);
    const newStatus = status ? 'deactive' : 'active';
    const updateStatusRequest = axiosInstance().patch(
      `${URL}/change-user-status/${id}`,
      { status: newStatus }
    );
    toast
      .promise(updateStatusRequest, {
        pending: `Updating user status...`,
        success: `User ${newStatus}d successfully!`,
        error: (error) => {
          const errors = error?.response?.data?.errors;
          const statusCode = error?.response?.status;
          if (statusCode === 401) {
            try {
              localStorage.removeItem('MH_UserData');
            } catch (error) {
              console.log(error);
            } finally {
              navigate('/Login');
            }
            return (
              errors || 'Unauthorized access. Redirecting to login.'
            );
          } else {
            handleErrors(error);
            return (
              errors ||
              `An error occurred while updating the user status to ${newStatus}`
            );
          }
        },
      })
      .then(() => {
        setLoading2(false);
        setStatus((pre) => !pre);
      })
      .catch((error) => {
        setLoading2(false);
        console.error(error);
      });
  };

  return loading ? (
    <Loader />
  ) : (
    <div className="grid grid-cols-12 gap-y-5 ">
      <div className="col-span-12 min-h-72  rounded-lg grid">
        <div className="flex flex-col relative">
          <div className="flex-1 bg-primaryGreen/80 rounded-t-md"></div>
          <div className="flex-1 bg-black/60 rounded-b-md flex justify-end"></div>
          <div className="absolute h-full w-full flex items-center px-5 text-white gap-5 justify-between">
            <div className="flex gap-x-5">
              <div className="h-40 w-40 rounded-full relative">
                {/* {profileImage ? ( */}
                <div>
                  {MH_UserData?.profileImage ? (
                    <img
                      src={`${StorageURL}/${MH_UserData?.profileImage}`}
                      className="absolute bg-white h-full w-full rounded-full border border-borderInput/30"
                    />
                  ) : (
                    <img
                      src={placeholder}
                      className="absolute bg-white h-full w-full rounded-full border border-borderInput/30"
                    />
                  )}
                </div>
              </div>
              <div className="flex flex-col gap-2 pt-24">
                <div className="headerText font-manrope">
                  {MH_UserData?.userName}
                </div>
                <div className="">
                  {MH_UserData?.userType == 'ap%4k45a5sd'
                    ? 'Admin'
                    : 'User'}
                </div>
              </div>
            </div>
            <div className="mx-7 mt-20">
              <label className="inline-flex items-center cursor-pointer">
                <input
                  checked={status}
                  type="checkbox"
                  disabled={loading2}
                  className="sr-only peer"
                  onChange={() => {
                    handleToggleStatus();
                  }}
                />
                <div className="relative w-8 h-4 py-2 bg-white/90 peer-focus:outline-none rounded-full after:bg-red-600 peer-checked:after:translate-x-3 peer-checked:after:bg-primaryGreen after:content-[''] after:absolute after:top-[1.99px] after:start-[4px] after:rounded-full after:h-3 after:w-3 after:transition-all"></div>
                <span className="ms-4 text-base text-white/80">
                  {status ? 'Activate' : 'Deactivated'}
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="col-span-12 text-white">
        <div className="grid grid-cols-12 space-x-5">
          <div className="col-span-3 card rounded-lg">
            <div className="flex flex-col">
              <label className="Info border-b border-borderInput px-7 py-5 headerText mt-[0.8rem]">
                Details
              </label>
              <div className="flex flex-col gap-y-7 p-7">
                <div className="flex flex-col">
                  <div className="flex gap-x-4">
                    <CgProfile className="text-2xl mt-1" />
                    <div className="flex flex-col">
                      <label className="primaryText">Name</label>
                      <p className="text-sm capitalize">
                        {MH_UserData?.userName}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="flex gap-x-4">
                    <MdOutlineMailOutline className="text-2xl mt-1" />
                    <div className="flex flex-col">
                      <label className="primaryText">Email</label>
                      <p className="text-sm">{MH_UserData?.email}</p>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="flex gap-x-4">
                    <FaRegStar className="text-2xl mt-1" />
                    <div className="flex flex-col">
                      <label className="primaryText">Role</label>
                      <p className="text-sm">
                        {MH_UserData?.userType == 'ap%4k45a5sd'
                          ? 'Admin'
                          : 'User'}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="flex gap-x-4">
                    <VscActivateBreakpoints className="text-2xl mt-1" />
                    <div className="flex flex-col">
                      <label className="primaryText">Status</label>
                      <p className="text-sm capitalize">
                        {status ? 'Active' : 'Deactive'}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-9 card rounded-lg p-7">
            <CustomTabs tabs={tabs} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewDetails;
