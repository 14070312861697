
import React from "react";
import { IoHomeOutline, IoTicketOutline, IoTicketSharp } from 'react-icons/io5';
import { TbCategory } from "react-icons/tb";
import { LuPackage2, LuUser2 } from "react-icons/lu";
import { FiUsers } from "react-icons/fi";
import { MdOutlineStadium } from "react-icons/md";
import { VscOrganization } from "react-icons/vsc";



const adminSidebarItems = [
    { path: 'admin/dashboard', label: 'Dashboard', iconPath: <IoHomeOutline /> },
    { path: 'all-events', label: 'Events', iconPath: <TbCategory /> },
    { path: 'all-packages', label: 'Packages', iconPath: <LuPackage2 /> },
    { path: 'all-users', label: 'Users', iconPath: <FiUsers /> },
    { path: 'all-teams', label: 'Teams', iconPath: <VscOrganization /> },
    { path: 'all-matches', label: 'Matches', iconPath: <MdOutlineStadium /> },
];

const userSidebarItems = [
    { path: 'dashboard', label: 'Dashboard', iconPath: <IoHomeOutline /> },
    { path: 'matches', label: 'Matches', iconPath: <LuPackage2 /> },
    { path: 'tickets', label: 'My Tickets', iconPath: <IoTicketOutline /> },
    {
        label: 'My Profile',
        iconPath: <LuUser2 />,
        children: [
            { path: '/profile', label: 'Profile Settings' },
            { path: '/payments', label: 'Payments' },
        ],
    },
];


export { adminSidebarItems, userSidebarItems };





