import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { Link, useNavigate } from 'react-router-dom';
import { GoChevronDown } from 'react-icons/go';
import { useAppContext } from '../../UseContext/ContextProvider';
import { toast } from 'react-toastify';
import { StorageURL } from '../../utilities/ConstantData';
export const ProfileDropdown = () => {
  const { openModal, closeModal, setIsOpen, isOpen, showBackdropWithContent, profileUpdation } = useAppContext()
  const dropdownRef = useRef(null);
  const navigate = useNavigate()
  const [MH_UserData, setMH_UserData] = useState()
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    if (isOpen) {
      closeModal()
    } else {
      openModal()
      showBackdropWithContent('')
    }
  };


  const handleLogout = () => {
    setIsOpen(false);
    closeModal()
    try {
      localStorage.removeItem('MH_UserData')
      toast.success("Logout successfully!")
    } catch (error) {
      console.log(error)
    } finally {
      navigate("/login")
    }

  }
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isOpen]);
  
  const handleItemClick = () => {
    setIsOpen(false);
    closeModal();
  };

  const getMH_UserData = () => JSON.parse(localStorage.getItem('MH_UserData'));

  useEffect(() => {
    setMH_UserData(getMH_UserData())
  }, [profileUpdation])
  return (
    <div className="hs-dropdown relative flex flex-col" ref={dropdownRef}>
      <button
        id="hs-dropdown-custom-trigger"
        type="button"
        className="hs-dropdown-toggle py-1 ps-1 pe-3 flex items-center gap-x-2 text-sm font-medium rounded-full borderbg-transparent shadow-sm  disabled:opacity-50 disabled:pointer-events-none"
        aria-haspopup="menu"
        aria-expanded={isOpen}
        aria-label="Dropdown"
        onClick={toggleDropdown}
      >
        <img
          className="w-10 h-10 bg-white rounded-full flex items-center object-cover"
          src={MH_UserData?.profileImage != "" ? `${StorageURL}/${MH_UserData?.profileImage}` : `assets/images/svgs/navbar/placeholder.png`}
          alt="Avatar"
        />
        <span className="font-medium truncate max-w-[7.5rem] text-white">{MH_UserData?.userName || ''}</span>
        <svg
          className={`transition-all ms-2 duration-150 ease-linear ${isOpen ? 'rotate-180' : ''}`}
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="m6 9 6 6 6-6" />
        </svg>
      </button>
      <div
        className={`hs-dropdown-menu transition-all duration-200 ease-linear ${isOpen ? 'h-28 opacity-100 mt-5' : 'h-0 opacity-0 overflow-hidden'} min-w-60 bg-[#121212] shadow-md rounded-lg p-1 space-y-0.5 mt-2 dark:bg-neutral-800 dark:border dark:border-neutral-700 absolute right-0 top-10`}
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="hs-dropdown-custom-trigger"
      >
        <ul class="py-2 text-sm text-white dark:text-gray-200" aria-labelledby="dropdownDividerButton">
          <li>
            <Link to='/profile' class="flex items-center gap-3 px-4 py-3" onClick={handleItemClick}>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.75 2.75C6.75 2.33579 6.41421 2 6 2C5.58579 2 5.25 2.33579 5.25 2.75V4.00879C3.43368 4.13698 2 5.65106 2 7.5V8.5C2 10.3489 3.43368 11.863 5.25 11.9912L5.25 17.25C5.25 17.6642 5.58579 18 6 18C6.41421 18 6.75 17.6642 6.75 17.25L6.75 11.9912C8.56632 11.863 10 10.3489 10 8.5V7.5C10 5.65106 8.56632 4.13698 6.75 4.00879V2.75ZM8.5 7.5C8.5 6.39543 7.60457 5.5 6.5 5.5H5.5C4.39543 5.5 3.5 6.39543 3.5 7.5V8.5C3.5 9.60457 4.39543 10.5 5.5 10.5H6.5C7.60457 10.5 8.5 9.60457 8.5 8.5V7.5Z" fill="#9B9B9B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.25 2.75L13.25 8.00879C11.4337 8.13698 10 9.65106 10 11.5V12.5C10 14.3489 11.4337 15.863 13.25 15.9912L13.25 17.25C13.25 17.6642 13.5858 18 14 18C14.4142 18 14.75 17.6642 14.75 17.25L14.75 15.9912C16.5663 15.863 18 14.3489 18 12.5V11.5C18 9.65106 16.5663 8.13698 14.75 8.00879L14.75 2.75C14.75 2.33579 14.4142 2 14 2C13.5858 2 13.25 2.33579 13.25 2.75ZM13.5 9.5C12.3954 9.5 11.5 10.3954 11.5 11.5V12.5C11.5 13.6046 12.3954 14.5 13.5 14.5H14.5C15.6046 14.5 16.5 13.6046 16.5 12.5V11.5C16.5 10.3954 15.6046 9.5 14.5 9.5H13.5Z" fill="#9B9B9B" />
              </svg>
              Profile Settings
            </Link>
          </li>
          <li onClick={() => handleLogout()} className='py-2'>
          <a href="#" class="flex items-center gap-3 px-4 text-sm ">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13 11.2C13.3866 11.2 13.7 10.8866 13.7 10.5C13.7 10.1134 13.3866 9.8 13 9.8L13 11.2ZM2.50503 10.005C2.23166 10.2784 2.23166 10.7216 2.50503 10.995L6.9598 15.4497C7.23316 15.7231 7.67638 15.7231 7.94975 15.4497C8.22311 15.1764 8.22311 14.7332 7.94975 14.4598L3.98995 10.5L7.94975 6.5402C8.22312 6.26683 8.22312 5.82362 7.94975 5.55025C7.67638 5.27688 7.23317 5.27688 6.9598 5.55025L2.50503 10.005ZM13 9.8L3 9.8L3 11.2L13 11.2L13 9.8Z" fill="#9B9B9B" />
              <path d="M10 7V6C10 4.61929 11.1193 3.5 12.5 3.5H14.5C15.8807 3.5 17 4.61929 17 6V15C17 16.3807 15.8807 17.5 14.5 17.5H12.5C11.1193 17.5 10 16.3807 10 15V14.5" stroke="#9B9B9B" stroke-width="1.5" stroke-linecap="round" />
            </svg>
            Sign Out
          </a>
          </li>
        </ul>
      </div>
    </div>
  );
};


// filterdropdowns

export const FilterDropdown = ({ isActive, onClick, children, text }) => {
  return (
    <div className="relative mb-2">
      <button
        className="py-2 bg-transparent text-white rounded-md flex justify-between w-full"
        onClick={onClick}
      >
        <span>{text}</span>
        <svg
          className={`transition-all duration-150 ease-linear ${isActive ? 'rotate-180' : ''} }`}
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="m6 9 6 6 6-6" />
        </svg>
      </button>
      <div className={`dropdown-content ${isActive ? 'active' : ''}`}>
        {children}
      </div>
    </div>
  );
};


// select dropdown
const Dropdown = ({
  id,
  title = 'Select',
  data,
  position = 'bottom-left',
  hasImage,
  style,
  selectedId,
  onSelect,
  label,
  disabled = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(
    selectedId ? data.find((item) => item._id == selectedId) : undefined
  );
  console.log(data)
  console.log("selectedId..", selectedId)
  console.log("selectedItem..", selectedItem)

  const dropdownRef = useRef(null);
  const handleChange = (item) => {
    setSelectedItem(item);
    onSelect && onSelect(item);
    setIsOpen(false);
  };

  useEffect(() => {
    if (selectedId && data) {
      const newSelectedItem = data.find((item) => item._id == selectedId);
      newSelectedItem && setSelectedItem(newSelectedItem);
    } else {
      setSelectedItem(undefined);
    }
  }, [selectedId, data]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const dropdownClass = classNames(
    'absolute bg-[#343434] w-full max-h-60 overflow-y-auto rounded shadow-md z-10 mt-2',
    {
      'cursor-not-allowed opacity-50': disabled,
    }
  );

  return (
    <div className='relative' ref={dropdownRef}>
      <div className='flex flex-col gap-y-3'>
        {label && (
          <label className={classNames('text-white text-base', { 'opacity-50': disabled })}>
            {label}
          </label>
        )}
        <div>
          <button
            id={id}
            aria-label='Toggle dropdown'
            aria-haspopup='true'
            aria-expanded={isOpen}
            type='button'
            onClick={() => !disabled && setIsOpen(!isOpen)}
            className={classNames(
              'flex justify-between items-center gap-5 rounded w-full py-2 px-4 bg-transparent focus:border-primaryGreen border border-borderInput text-white',
              style,
              { 'cursor-not-allowed opacity-50': disabled }
            )}
            disabled={disabled}
          >
            <div className='flex items-center gap-5'>
              {selectedItem?.logo && (
                <img
                  src={`${StorageURL}/${selectedItem.logo}`}
                  alt='Selected'
                  className='w-7 h-7 rounded-full bg-gray-400 object-cover me-2'
                />
              )}
              <span>{selectedItem?.name || title}</span>
            </div>
            <GoChevronDown
              size={20}
              className={classNames('transform duration-500 ease-in-out', {
                'rotate-180': isOpen,
              })}
            />
          </button>
          {isOpen && (
            <div aria-label='Dropdown menu' className={dropdownClass}>
              <ul
                role='menu'
                aria-labelledby={id}
                aria-orientation='vertical'
                className='leading-10'
              >
                {data.map((item) => (
                  <li
                    key={item.id}
                    onClick={() => !disabled && handleChange(item)}
                    className={classNames(
                      'flex items-center cursor-pointer px-3 gap-3 text-white py-3 border-b border-borderInput hover:bg-black/30',
                      { 'bg-black/30': selectedItem?.id === item.id }
                    )}
                  >
                    {hasImage && item.logo && (
                      <img
                        src={`${StorageURL}/${item.logo}`}
                        alt={item.name}
                        loading='lazy'
                        className='w-7 h-7 rounded-full bg-gray-400 object-cover me-2'
                      />
                    )}
                    <span>{item.name}</span>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
