import React, { useEffect, useState } from 'react';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../Components/UiElements/Buttons';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../UseContext/ContextProvider';
import {
  axiosInstance,
  StorageURL,
  URL,
} from '../../utilities/ConstantData';
import Loader from '../../Components/UiElements/Loader';
import { toast } from 'react-toastify';
import { BiCalendarWeek } from 'react-icons/bi';
const SelectPackage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const {
    handleErrors,
    selectedUserMatch,
    setSelectedUserPackage,
    convertToAmPm,
    convertToDateFormat,
  } = useAppContext();
  const [packagesData, setPackageData] = useState();
  const packageDetails = (item) => {
    navigate('/package-details');
    setSelectedUserPackage(item);
  };
  useEffect(() => {
    fetchPackages();
    if (!selectedUserMatch) {
      navigate(-1);
    }
  }, [selectedUserMatch]);

  const fetchPackages = async () => {
    setLoading(true);
    axiosInstance()
      .get(`${URL}/package/all`)
      .then((res) => {
        const data = res.data.data;
        const filteredData = data.filter(
          (item) => item.status != 'deactive'
        );
        setPackageData(filteredData);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        const errors = error?.response?.data?.errors;
        const statusCode = error?.response?.status;
        if (statusCode == 401) {
          toast.error(errors);
          try {
            localStorage.removeItem('MH_UserData');
          } catch (error) {
            console.log(error);
          } finally {
            navigate('/Login');
          }
        } else {
          handleErrors(error);
        }
      });
  };

  return loading ? (
    <Loader />
  ) : (
    <div className="grid grid-cols-12 lg:gap-x-5 gap-y-10">
      <div className="col-span-12">
        <SecondaryButton />
      </div>
      <div className="col-span-12">
        <div className="grid grid-cols-12 gap-y-5">
          <div className="col-span-12">
            <div className="flex justify-between items-center">
              <div className="headerText">Selected Match</div>
              {/* <PrimaryButton size='medium'>Proceed to Checkout</PrimaryButton> */}
            </div>
          </div>
          <div className="col-span-12">
            <div className="grid grid-cols-12">
              <div className="col-span-4">
                <div className="border border-borderInput rounded-lg p-5 grid grid-cols-12 text-white items-center gap-y-5">
                  <div className="col-span-12 text-sm">
                    <div className="grid grid-cols-12 items-center">
                      <div className="col-span-3">
                        <div className="flex flex-col gap-y-2 items-center justify-center">
                          <div className="h-16 w-16 relative">
                            <img
                              src={`${StorageURL}/${selectedUserMatch?.team1?.logo}`}
                              className="absolute h-full w-full object-contain"
                            />
                          </div>
                          <div>{selectedUserMatch?.team1?.name}</div>
                        </div>
                      </div>
                      <div className="col-span-6">
                        <div className="flex flex-col gap-y-2 items-center text-center">
                          <div className='text-secondaryText text-[1rem]'>
                            {selectedUserMatch?.category?.name || ''}
                          </div>
                          <div className='text-2xl font-semibold'>VS</div>
                          <div className="flex gap-x-1 items-center">
                            <div className="h-2 w-2 bg-red-500 rounded-full"></div>
                            <div className='text-secondaryText'>
                              {convertToAmPm(selectedUserMatch.time)}
                            </div>
                          </div>
                          <span className="text-xs text-center text-secondaryText items-center justify-center flex gap-x-1">
                          <BiCalendarWeek size={15}/>
                            {convertToDateFormat(
                              selectedUserMatch.date
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="col-span-3">
                        <div className="flex flex-col gap-y-2 items-center">
                          <div className="h-16 w-16 relative">
                            <img
                            src={`${StorageURL}/${selectedUserMatch?.team2?.logo}`}
                            className="absolute h-full w-full object-contain"
                            />
                          </div>
                          <div>{selectedUserMatch?.team2?.name}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-span-12">
        <div className="grid grid-cols-12 gap-5">
          <div className="col-span-12 capitalize headerText text-xl">
            select one of our hospitality packages
          </div>
          <div className="col-span-12">
            <div className="grid grid-cols-12 gap-5">
              {packagesData.length > 0 ? (
                packagesData.map((item, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => packageDetails(item)}
                      className="lg:col-span-4 col-span-12 bg-[#161616] text-[#464646] rounded-2xl p-6 min-h-[17rem]  border-borderInput cursor-pointer border hover:border-primaryGreen transition-all duration-300 ease-linear"
                    >
                      <div className="flex flex-col">
                        <div className="flex flex-col lg:gap-x-8 gap-y-">
                          <div className="flex flex-col gap-5">
                            <h1 className="text-3xl font-bold text-white">
                              {item.name}
                            </h1>
                            <p className="text-sm w-11/12 items-center justify-center text-[#aaaaaa]">
                              {item.description}
                            </p>
                            <h1 className="text-3xl font-bold text-white">
                              ${item.price}
                            </h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div>
                  <h2 className="text-white">No Package Found!</h2>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectPackage;
