import React, { useEffect, useState } from 'react';
import banner from '../../../src/assets/images/tickets/banner.png';
import bardCode from '../../../src/assets/images/tickets/Bardcode.png';
import CustomTabs from '../../Components/Tabs/CustomTabs';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../Components/UiElements/Buttons';
import { TbClock } from 'react-icons/tb';
import { IoLocationOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { axiosInstance, StorageURL, URL } from '../../utilities/ConstantData';
import { useAppContext } from '../../UseContext/ContextProvider';
import Loader from '../../Components/UiElements/Loader';
const Tickets = () => {
  const navigate = useNavigate();
  const [UpcomingBookings, setUpcomingBookings] = useState();
  const [PastBookings, setPastBookings] = useState();
  const { handleErrors,convertToAmPm, extractDate, getDayOfWeek, extractMonth, setSelectedOrderDetail } = useAppContext();
  const [loading , setLoading] = useState(true)
  useEffect(() => {
    fetchData();
  }, []);


  const fetchData = async () => {
    const Uid = JSON.parse(localStorage.getItem('MH_UserData')).id;
    try {
      const res = await axiosInstance().get(
        `${URL}/booking/user/${Uid}`
      );
      const bookings = res.data.data.bookings;
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const upcoming = bookings.filter(
        (booking) => new Date(booking.match.date) >= today
      );
      const past = bookings.filter(
        (booking) => new Date(booking.match.date) < today
      );
      setUpcomingBookings(upcoming);
      setPastBookings(past);
      setLoading(false)
    } catch (error) {
        setLoading(false)
       handleApiError(error);
    }
  };

  const handleApiError = (error) => {
    const errors = error?.response?.data?.errors;
    const statusCode = error?.response?.status;
    if (statusCode === 401) {
      toast.error(errors);
      localStorage.removeItem('MH_UserData');
      navigate('/Login');
    } else {
      handleErrors(error);
    }
  };

  const handleMoreInfo= (item) =>{
    setSelectedOrderDetail(item)
    navigate('/tickets-details')
  }

  const tabs = [
    {
      label: 'Upcoming',
      content: (
        <>
          { UpcomingBookings?.length > 0 ?
            UpcomingBookings
            .map((item) => {
              return (
                <>
                  <div className="grid grid-cols-1 mt-10">
                    <div className="relative min-h-[22rem]">
                      <img
                        className="absolute h-full w-full"
                        src={banner}
                        alt="banner"
                      />
                      <div className="absolute w-full h-full p-10 grid grid-cols-12 gap-x-5">
                        <div className="col-span-4 relative border rounded-xl">
                          <img
                            src={`${StorageURL}/${item?.match?.category?.banner_image}`}
                            className="h-full w-full absolute object-contain"
                          />
                        </div>
                        <div className="col-span-7">
                          <div className="grid grid-cols-12 h-full gap-x-5">
                            <div className="col-span-3 bg-primaryGreen rounded-xl text-2xl font-bold flex items-center justify-center text-center">
                              {extractDate(item?.match?.date)} <br /> {extractMonth(item?.match?.date)}
                            </div>
                            <div className="col-span-9">
                              <div className="grid grid-cols-1 gap-y-5">
                                <div className="grid grid-cols-3 items-center border-b pb-5 border-black">
                                  <div className="col-span-2">
                                    <PrimaryButton
                                      size="medium"
                                      className="!bg-gray-200 !text-black"
                                    >
                                      {getDayOfWeek(item?.match?.date)}
                                    </PrimaryButton>
                                  </div>
                                  <div>{`Order No. ${item.bookingNo}`}</div>
                                </div>
                                <div className="grid grid-cols-1 gap-y-5">
                                  <div className="flex gap-x-5 text-2xl font-extrabold font-monrope">
                                    <span>{item?.match?.team1?.name}</span>
                                    <span>VS</span>
                                    <span>{item?.match?.team2?.name}</span>
                                  </div>
                                  <div className="text-sm font-semibold w-[70%]">
                                    {item?.match?.category?.description}
                                  </div>
                                  <div className="grid grid-cols-2">
                                    <div className="grid grid-cols-2 items-center">
                                      <div className="flex gap-x-2 items-center">
                                        <TbClock size={20} />
                                        <span className="text-sm">
                                        {convertToAmPm(item?.match?.time)}
                                        </span>
                                      </div>
                                      <div className="flex gap-x-2">
                                        <IoLocationOutline
                                          size={20}
                                        />
                                        <span className="text-sm">
                                          {item?.match?.venue}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="grid justify-end">
                                      <PrimaryButton
                                        onClick={() =>
                                          handleMoreInfo(item)
                                        }
                                        size="medium"
                                        className="border-2 border-black"
                                      >
                                        More Info
                                      </PrimaryButton>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="grid">
                          <img
                            src={bardCode}
                            alt="bardcode"
                            className="h-full"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            }): <div className=''>
            <h5 className='text-center font-bold mt-10 text-[#88F67E]'>No upcoming tickets found!</h5>
           </div>}
        </>
      ),
    },
    {
      label: 'Past',
      content: (
        <>
          { PastBookings?.length > 0 ?
            PastBookings
            .map((item) => {
              return (
                <>
                  <div className="grid grid-cols-1 mt-10">
                    <div className="relative min-h-[22rem]">
                      <img
                        className="absolute h-full w-full"
                        src={banner}
                        alt="banner"
                      />
                      <div className="absolute w-full h-full p-10 grid grid-cols-12 gap-x-5">
                        <div className="col-span-4 relative border rounded-xl">
                          <img
                            src={`${StorageURL}/${item?.match?.category?.banner_image}`}
                            className="h-full w-full absolute object-contain"
                          />
                        </div>
                        <div className="col-span-7">
                          <div className="grid grid-cols-12 h-full gap-x-5">
                            <div className="col-span-3 bg-primaryGreen rounded-xl text-2xl font-bold flex items-center justify-center text-center">
                              {extractDate(item?.match?.date)} <br /> {extractMonth(item?.match?.date)}
                            </div>
                            <div className="col-span-9">
                              <div className="grid grid-cols-1 gap-y-5">
                                <div className="grid grid-cols-3 items-center border-b pb-5 border-black">
                                  <div className="col-span-2">
                                    <PrimaryButton
                                      size="medium"
                                      className="!bg-gray-200 !text-black"
                                    >
                                      {getDayOfWeek(item?.match?.date)}
                                    </PrimaryButton>
                                  </div>
                                  <div>{`Order No. ${item.bookingNo}`}</div>
                                </div>
                                <div className="grid grid-cols-1 gap-y-5">
                                  <div className="flex gap-x-5  text-2xl font-extrabold font-jumper">
                                    <span>{item?.match?.team1?.name}</span>
                                    <span>VS</span>
                                    <span>{item?.match?.team2?.name}</span>
                                  </div>
                                  <div className="text-sm font-semibold w-[70%]">
                                    {item?.match?.category?.description}
                                  </div>
                                  <div className="grid grid-cols-2">
                                    <div className="grid grid-cols-2 items-center">
                                      <div className="flex gap-x-2 items-center">
                                        <TbClock size={20} />
                                        <span className="text-sm">
                                        {convertToAmPm(item?.match?.time)}
                                        </span>
                                      </div>
                                      <div className="flex gap-x-2">
                                        <IoLocationOutline
                                          size={20}
                                        />
                                        <span className="text-sm">
                                          {item?.match?.venue}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="grid justify-end">
                                      <PrimaryButton
                                        onClick={() =>
                                          handleMoreInfo(item)
                                        }
                                        size="medium"
                                        className="border-2 border-black"
                                      >
                                        More Info
                                      </PrimaryButton>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="grid">
                          <img
                            src={bardCode}
                            alt="bardcode"
                            className="h-full"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            }) : <div className=''>
               <h5 className='text-center font-bold mt-10 text-[#88F67E]'>No past tickets found!</h5>
              </div>
              }
        </>
      ),
    },
  ];
  return loading? <Loader/> : (
    <div className="grid grid-cols-12 gap-y-10">
      <div className="col-span-12">
        <SecondaryButton />
      </div>
      <div className="col-span-12 headerText">My Tickets</div>
      <div className="col-span-12">
        <CustomTabs tabs={tabs} />
      </div>
    </div>
  );
};

export default Tickets;
