import React, { useEffect, useState } from 'react';
import MatchCard from '../../Components/Cards/MatchCard';
import { PrimaryButton } from '../../Components/UiElements/Buttons';
import { useNavigate } from 'react-router-dom';
import { axiosInstance, URL } from '../../utilities/ConstantData';
import Loader from '../../Components/UiElements/Loader';
import { useAppContext } from '../../UseContext/ContextProvider';
import { toast } from 'react-toastify';

const AllMatches = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const {
    handleErrors,
    setCategoryData,
    setPackageData,
    MatchesData,
    setMatchesData,
    searchTxt,
  } = useAppContext();
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    fetchData();
    fetchCategories();
    fetchPackages();
  }, []);

  useEffect(() => {
    if (MatchesData) applyFilters();
  }, [MatchesData, searchTxt]);

  const applyFilters = () => {
    if (searchTxt) {
      const filteredMatches = MatchesData.filter(
        (data) =>
          data?.team1?.name
            .toLowerCase()
            .includes(searchTxt.toLowerCase()) ||
          data?.team2?.name
            .toLowerCase()
            .includes(searchTxt.toLowerCase()) ||
          data?.category?.name
            .toLowerCase()
            .includes(searchTxt.toLowerCase())
      );
      setFilteredData(filteredMatches);
    } else {
      setFilteredData(MatchesData);
    }
  };

  const fetchData = async () => {
    try {
      const res = await axiosInstance().get(`${URL}/event/all`);
      const data = res.data.data;

      const activeMatches = data.filter(
        (item) =>
          item?.category?.status != 'inactive' &&
            item?.category?.status &&
            item?.team1?.status != 'inactive' &&
            item?.team1?.status &&
            item?.team2?.status != 'inactive' &&
            item?.team2?.status
      );

      setMatchesData(activeMatches);
      setFilteredData(activeMatches);
    } catch (error) {
      setLoading(false);
      const errors = error?.response?.data?.errors;
      const statusCode = error?.response?.status;
      if (statusCode === 401) {
        toast.error(errors);
        localStorage.removeItem('MH_UserData');
        navigate('/Login');
      } else {
        handleErrors(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchCategories = async () => {
    try {
      const res = await axiosInstance().get(`${URL}/category/all`);
      const data = res.data.data;

      const activeCategories = data.filter(
        (item) => item.status !== 'inactive'
      );

      setCategoryData(activeCategories);
    } catch (error) {
      handleApiError(error);
    }
  };

  const fetchPackages = async () => {
    try {
      const res = await axiosInstance().get(`${URL}/package/all`);
      const data = res.data.data;

      const activePackages = data.filter(
        (item) => item.status !== 'deactive'
      );

      setPackageData(activePackages);
    } catch (error) {
      handleApiError(error);
    }
  };

  const handleApiError = (error) => {
    const errors = error?.response?.data?.errors;
    const statusCode = error?.response?.status;
    if (statusCode === 401) {
      toast.error(errors);
      localStorage.removeItem('MH_UserData');
      navigate('/Login');
    } else {
      handleErrors(error);
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <div className="grid grid-cols-12 gap-y-10">
      <div className="col-span-12">
        <div className="flex justify-between items-center">
          <p className="headerText">All Matches</p>
          <PrimaryButton
            size="medium"
            onClick={() => navigate('/match/create')}
          >
            Create New Match
          </PrimaryButton>
        </div>
      </div>
      <div className="col-span-12">
        {filteredData.length > 0 ? (
          <div className="grid grid-cols-12 gap-5">
            {filteredData.map((item) => (
              <MatchCard
                key={item.id}
                data={item}
                fontSize="text-[12px]"
                width="large"
                overlay={true}
              />
            ))}
          </div>
        ) : (
          <div>
            <h2 className="text-white text-center">
              No match found!
            </h2>
          </div>
        )}
      </div>
    </div>
  );
};

export default AllMatches;
